import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, TextField } from "@mui/material";
import CommonDialog from "../../common/commonDialog";

const useStyles = makeStyles((theme) => ({
  checkLabelFont: {
    marginLeft: "12px!important",
    color: "#292929!important",
    fontSize: "1rem!important",
  },
  checkFont: {
    display: "flex",
    justifyContent: "start",
    marginLeft: "0px!important",
  },
}));

const DistributorMappingModal = (props) => {
  const classes = useStyles();

  const {
    open,
    onCancel,
    onSave,
    distributorData,
    setDistributorData,
    bmComboList,
    bsmComboList,
    asmComboList,
    coOrdinatorComboList,
    divisionData,
    data,
  } = props;
  const onInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "sapPartyCode") {
      const item = data.find((itemD) => itemD.sapPartyCode === value);
      distributorData.distributorMasterId = item.distributorMasterId;
    }
    distributorData[name] = value;
    setDistributorData({ ...distributorData });
  };

  return (
    <CommonDialog
      onCancel={onCancel}
      onSave={onSave}
      open={open}
      primaryButtonLabel={"Save"}
      secondaryButtonLabel={"Cancel"}
      title={"Distributor Mapping"}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        {/* <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Region"
            defaultValue=""
            size="small"
            name="regionMasterId"
            value={distributorData.regionMasterId}
            onChange={onInputChange}
          >
             {regionData.map((option) => (
              <MenuItem key={option.regionMasterId} value={option.regionMasterId}>
                {option.regionName}
              </MenuItem>
            ))}
          </TextField>
        </Grid> */}
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="sapPartyCode"
            select
            label="SAP Party Code"
            size="small"
            name="sapPartyCode"
            value={distributorData.sapPartyCode}
            onChange={onInputChange}
          >
            {data.map((option) => (
              <MenuItem key={option.sapPartyCode} value={option.sapPartyCode}>
                {option.sapPartyCode}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Division"
            name="divisionMasterId"
            value={distributorData.divisionMasterId}
            onChange={onInputChange}
            defaultValue=""
            size="small"
          >
            {divisionData.map((option) => (
              <MenuItem
                key={option.divisionMasterId}
                value={option.divisionMasterId}
              >
                {option.divisionName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Main Agent"
            size="small"
            name="mainAgent"
            value={distributorData.mainAgent}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Sub Agent"
            size="small"
            name="subAgent"
            value={distributorData.subAgent}
            onChange={onInputChange}
          />
        </Grid>

        {/* <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Main Party"
            size="small"
            name="mainParty"
            value={distributorData.mainParty}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Sub Party"
            size="small"
            name="subParty"
            value={distributorData.subParty}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField fullWidth id="outlined-search" label="City" size="small" />
        </Grid> */}

        {/* <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="State"
            defaultValue=""
            size="small"
            name="stateMasterId"
            value={distributorData.stateMasterId}
            onChange={onInputChange}
          >
            {stateData.map((option) => (
              <MenuItem key={option.stateMasterId} value={option.stateMasterId}>
                {option.stateName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Address"
            name="address"
            type="text"
            size="small"
            value={distributorData.address}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="SAP Party Code"
            size="small"
            type="number"
            name="sapPartyCode"
            value={distributorData.sapPartyCode}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Mail Id"
            size="small"
            type="mail"
            name="emailId"
            value={distributorData.emailId}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Mobile No"
            size="small"
            type="number"
            name="mobileNo"
            value={distributorData.mobileNo}
            onChange={onInputChange}
          />
        </Grid> */}

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="BM Name"
            size="small"
            name="bmName"
            value={distributorData.bmName}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="BM Email"
            size="small"
            name="bmEmail"
            value={distributorData.bmEmail}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Co-ordinator 1 Name"
            size="small"
            name="coOrdinatorOneName"
            value={distributorData.coOrdinatorOneName}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Co-ordinator 1 Email"
            size="small"
            name="coOrdinatorOneEmail"
            value={distributorData.coOrdinatorOneEmail}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Co-ordinator 2 Name"
            size="small"
            name="coOrdinatorTwoName"
            value={distributorData.coOrdinatorTwoName}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Co-ordinator 2 Email"
            size="small"
            name="coOrdinatorTwoEmail"
            value={distributorData.coOrdinatorTwoEmail}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="BCE Name"
            size="small"
            name="bceName"
            value={distributorData.bceName}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="BCE Email"
            size="small"
            name="bceEmail"
            value={distributorData.bceEmail}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="BCE Mobile No"
            size="small"
            name="bceMobileNo"
            value={distributorData.bceMobileNo}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="BCM Name"
            size="small"
            name="bcmName"
            value={distributorData.bcmName}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="BCM Email"
            size="small"
            name="bcmEmail"
            value={distributorData.bcmEmail}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="BCM Mobile No"
            size="small"
            name="bcmMobileNo"
            value={distributorData.bcmMobileNo}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Pay Term1 Days"
            size="small"
            name="paymentTerms1Days"
            type="number"
            value={distributorData.paymentTerms1Days}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Pay Term1 %"
            size="small"
            name="paymentTerms1Percent"
            value={distributorData.paymentTerms1Percent}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Pay Term2 Days (Base)"
            size="small"
            name="paymentTerms2Days"
            type="number"
            value={distributorData.paymentTerms2Days}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Pay Term2 % (Base)"
            size="small"
            name="paymentTerms2Percent"
            value={distributorData.paymentTerms2Percent}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Pay Term3 Days"
            size="small"
            name="paymentTerms3Days"
            type="number"
            value={distributorData.paymentTerms3Days}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Pay Term3 %"
            size="small"
            name="paymentTerms3Percent"
            value={distributorData.paymentTerms3Percent}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Pay Term4 Days"
            size="small"
            name="paymentTerms4Days"
            type="number"
            value={distributorData.paymentTerms4Days}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Pay Term4 %"
            size="small"
            name="paymentTerms4Percent"
            value={distributorData.paymentTerms4Percent}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Payment Terms"
            size="small"
            name="paymentTerms"
            value={distributorData.paymentTerms}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            value={data.distributorMasterId}
            onChange={onInputChange}
            label="distributorMaster"
            size="small"
            hidden
          />
        </Grid>

        {/* <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Co-ordinator 2"
            defaultValue=""
            size="small"
            name="coOrdinatorTwo"
            value={distributorData.coOrdinatorTwo}
            onChange={onInputChange}
          ></TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="GST No"
            size="small"
            name="gstNo"
            value={distributorData.gstNo}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="PAN"
            size="small"
            name="panNo"
            value={distributorData.panNo}
            onChange={onInputChange}
          />
        </Grid> */}

        {/* <Grid item sm={12} xs={12}>
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className={classes.checkLabelFont}
            >
              Status
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="status"
              value={distributorData.status}
              onChange={onInputChange}
            >
              <FormControlLabel
                value="Y"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    fontSize={15}
                    sx={{ color: "#292929" }}
                  >
                    ACTIVE
                  </Typography>
                }
                className={classes.checkFont}
              />
              <FormControlLabel
                value="N"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    fontSize={15}
                    sx={{ color: "#292929" }}
                  >
                    INACTIVE
                  </Typography>
                }
                className={classes.checkFont}
              />
            </RadioGroup>
          </FormControl>
        </Grid> */}
      </Grid>
    </CommonDialog>
  );
};
export default DistributorMappingModal;
