import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardBody, Container, Row } from "reactstrap";
import { Button } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { PersonSearch, Refresh,SimCardDownloadRounded } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CreditExtensionModal from "./creditExtensionModal";
import CommonAccordion from "../../common/commonAccordion";
import CommonTable from "../../common/commonTable";
import {
  creditExtensionTableData,
  creditExtensionTableHeader,
} from "../../common/commonConstant";
import { useEffect } from "react";
import {
  exportToExcelCreditExtension,
  getAllCreditExtension,
  getAllDistributorMasters,
  saveOrUpdateCreditExtension,
  searchCreditExtensionData,
} from "../../api/service";
import CommonLoader from "../../common/commonLoader";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const status = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Reject",
    label: "Reject",
  },
  
];


const CreditExtension = () => {
  const classes = useStyles();
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const initialValues = {
    creditExtensionId: null,
    customerCode: "",
    invoiceNumber: "",
    documentDate:"",
    distributorId:null,	
    cdextensionDays:null,
    reason:"",
    otherReason:"",
    remarks:"",
    approvalStatus: "",
    approvalRemarks: "",
    sendNotify:""

  };

  const [data, setData] = useState([]);


  const [creditExtensionData, setCreditExtensionData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState([]);

  const filterInitialValues = {
    searchCustomerCode: "",
    searchStatus: "",
  };
  const [searchData, setSearchData] = useState(filterInitialValues);

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {
    setLoading(true);
    getAllCreditExtension(page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });

    getAllDistributorMasters(page, rowsPerPage).then((res) => {
      setSearch(res.response.responseObject);
    });
  };
  const [open, setOpen] = useState(false);

  const onOpenModal = (data = initialValues) => {
    setCreditExtensionData(data);
    setOpen(true);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    getAllCreditExtension(newPage, rowsPerPage).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getAllCreditExtension(0, event.target.value).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const onHandleSave = () => {
    setLoading(true);
    saveOrUpdateCreditExtension(creditExtensionData).then((res) => {
      if (creditExtensionData.creditExtensionId) {
        toast.success("Successfully Updated !", { autoClose: 1000 });
      } else {
        toast.success("Successfully Created !", { autoClose: 1000 });
      }
      setLoading(false);
      setOpen(false);
      setCreditExtensionData(initialValues);
      loadListData();
    });
  };

  const filterData = () => {
    setLoading(true);
    searchCreditExtensionData(searchData, page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.responseMap.data);
    });
  };

  const exportData = () => {
    setLoading(true);
    exportToExcelCreditExtension(
      searchData.searchCustomerCode,
      moment(new Date()).format("DD_MM_YYYY_HH_MM_SS")
    ).then(() => {
      setLoading(false);
    });
  };


  return (
    <Fragment>
            {loading ? <CommonLoader /> : null}
      <Breadcrumb
        title="Credit Extension"
      />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <CommonAccordion accordionTitle={"Advanced Search"}>
            <Grid container columnSpacing={2} className={classes.muiGrid}>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Customer Code"
                  size="small"
                  id="fullWidth"
                  name="searchCustomerCode"
                  value={searchData.searchCustomerCode}
                  onChange={onFilterInputChange}
                >  {search.map((data) => (
                  <MenuItem
                    key={data.sapPartyCode}
                    value={data.sapPartyCode}
                  >
                    {data.sapPartyCode}
                  </MenuItem>
                ))}
                  </TextField>
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Status"
                  size="small"
                  name="searchStatus"
                  value={searchData.searchStatus}
                  onChange={onFilterInputChange}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                sm={4}
                xs={12}
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => filterData()}
                >
                  <PersonSearch></PersonSearch>
                </Button>
                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                    setSearchData(filterInitialValues);
                    loadListData();
                  }}
                >
                  <Refresh></Refresh>
                </Button>
                <Button
                      color="primary"
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                      type="button"
                      className={classes.btnClass}
                      onClick={() => {
                        exportData();
                      }}
                    >
                      <SimCardDownloadRounded></SimCardDownloadRounded>
                    </Button>
              </Grid>
            </Grid>
          </CommonAccordion>
          <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  tableData={data}
                  tableHeaders={creditExtensionTableHeader}
                  tableColumns={creditExtensionTableData}
                  editId={"creditExtensionId"}
                  editMode={true}
                  onOpenModal={onOpenModal}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={totalRecord}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                />
              </div>
            </CardBody>
          </Card>
        </Row>

        <CreditExtensionModal
          open={open}
          onCancel={() => {
            setOpen(false);
            setCreditExtensionData(initialValues);
          }}
          onSave={onHandleSave}
          creditExtensionData={creditExtensionData}
          setCreditExtensionData={setCreditExtensionData}
       
        />
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default CreditExtension;
