import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardBody, Container, Row } from "reactstrap";

import { Button } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { ToastContainer } from "react-toastify";

import { PersonSearch, Refresh } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonAccordion from "../../common/commonAccordion";
import CommonTable from "../../common/commonTable";
import {
  ndcTableData,
  ndcTableHeader,
} from "../../common/commonConstant";
import { useEffect } from "react";
import {
  getAllDistributorMasters,
  getAllRegionMasters,
  getAllStateMasters,
  getBalanceConfirmationDtlByCustomerCode,
} from "../../api/service";
import CommonLoader from "../../common/commonLoader";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));



const Ndc = () => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalRecord, setTotalRecord] = React.useState(0);

  const [data, setData] = useState([]);
  const [search, setSearch] = useState([]);
  const [searchRegion, setSearchRegion] = useState([]);
  const [searchState, setSearchState] = useState([]);

  const [loading, setLoading] = useState(false);

  const filterInitialValues = {
    searchCustomerCode: "",
    searchRegion: "",
    searchState: "",

  };
  const [searchData, setSearchData] = useState(filterInitialValues);

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {

    setLoading(true);
    getBalanceConfirmationDtlByCustomerCode("", page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });

    getAllDistributorMasters(page, rowsPerPage).then((res) => {
      setSearch(res.response.responseObject);
    });

    getAllRegionMasters(page, rowsPerPage).then((res) => {
      setSearchRegion(res.response.responseObject);
    });

    getAllStateMasters(page, rowsPerPage).then((res) => {
      setSearchState(res.response.responseObject);
    });
  };

  const [open, setOpen] = useState(false);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getBalanceConfirmationDtlByCustomerCode("", newPage, rowsPerPage).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getBalanceConfirmationDtlByCustomerCode("", 0, event.target.value).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };



  const filterData = () => {
    setLoading(true);
    getBalanceConfirmationDtlByCustomerCode(searchData.searchCustomerCode, page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
    });


  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb
        title="NDC"
      />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <CommonAccordion accordionTitle={"Advanced Search"}>
            <Grid container columnSpacing={2} className={classes.muiGrid}>

              <Grid item sm={4} xs={12}>
              <TextField
                      fullWidth
                      label="Customer Code"
                      size="small"
                      select
                      id="fullWidth"
                      name="searchCustomerCode"
                      value={searchData.searchCustomerCode}
                      onChange={onFilterInputChange}
                    >
                 {search.map((data) => (
                  <MenuItem
                    key={data.sapPartyCode}
                    value={data.sapPartyCode}
                  >
                    {data.sapPartyCode}
                  </MenuItem>
                ))}</TextField>
              </Grid>

              <Grid
                item
                sm={4}
                xs={12}
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => filterData()}
                >
                  <PersonSearch></PersonSearch>
                </Button>
                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                        setSearchData(filterInitialValues);
                        loadListData();
                  }}
                >
                  <Refresh></Refresh>
                </Button>
              </Grid>
            </Grid>
          </CommonAccordion>
          <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  tableData={data}
                  tableHeaders={ndcTableHeader}
                  tableColumns={ndcTableData}
                  editId={"balanceConfirmationId"}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={totalRecord}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                />
              </div>
            </CardBody>
          </Card>
        </Row>

      
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default Ndc;
