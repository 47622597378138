import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CommonDialog from "../../common/commonDialog";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  checkLabelFont: {
    marginLeft: "12px!important",
    color: "#292929!important",
    fontSize: "1rem!important",
  },
}));

const BalanceConfirmationHistoryModal = (props) => {
  const classes = useStyles();
  const { open, handleClose, data } = props;
  const [claimDetails, setClaimDetails] = useState([]);

  useEffect(() => {
    setClaimDetails(data.claimDetails);
  }, [data.claimDetails]);

  return (
    <>
      <CommonDialog
        onCancel={handleClose}
        onSave={handleClose}
        open={open}
        primaryButtonLabel={"Close"}
        title={"Balance Confirmation Details"}
        maxWidth="md"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <div>
              The balance on your account as per our records bear an amount of
              <b>
                {" "}
                <Link to={data.filePath} target="_blank">
                  Rs.{data.closingBalance?.toFixed(0)}-
                  {data.balanceConfirmationType}{" "}
                </Link>
              </b>
              (DR - receivable from you / CR - payable to you). A detailed
              statement on the same is attached to this letter. We are
              requesting you to confirm that apart from the above, there is no
              due payable from Modenik Lifestyle Private Limited as on date for
              the balance on <b>{data.balanceConfirmationDate}</b>. In case,
              there is any difference then you are requested to share the
              details of the same as under:
            </div>
            <hr />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="balanceConfirmationStatus"
                value={data.balanceConfirmationStatus}
              >
                <FormControlLabel
                  value="Agreed"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#f91e26",
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 23,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body1"
                      fontSize={15}
                      sx={{ color: "#292929" }}
                    >
                      Agree
                    </Typography>
                  }
                  className={classes.checkFont}
                />
                <FormControlLabel
                  value="Disagreed"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#f91e26",
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 23,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body1"
                      fontSize={15}
                      sx={{ color: "#292929" }}
                    >
                      Disagree
                    </Typography>
                  }
                  className={classes.checkFont}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {data.balanceConfirmationStatus === "Disagreed" && (
            <>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-search"
                  label="What is your balance?"
                  size="small"
                  type="number"
                  name="customerBalanceAmount"
                  value={data.customerBalanceAmount}
                />
              </Grid>
              <Grid item xs={12}>
                <hr />
                <div>
                  *Add you claims details for your breakup amount{" "}
                  <>
                    {data.customerBalanceAmount && (
                      <span style={{ color: "#f91e26" }}>
                        Rs.{" "}
                        {data.balanceConfirmationType === "CR"
                          ? data.closingBalance - data.customerBalanceAmount > 0
                            ? (
                                data.closingBalance - data.customerBalanceAmount
                              ).toFixed(0)
                            : -(
                                data.closingBalance - data.customerBalanceAmount
                              ).toFixed(0)
                          : (
                              data.closingBalance - data.customerBalanceAmount
                            ).toFixed(0)}
                      </span>
                    )}
                  </>
                </div>
              </Grid>
              {claimDetails?.length > 0 && (
                <Grid item sm={12} xs={12}>
                  <div className="user-status table-responsive text-center">
                    <table className="table table-bordernone mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Period (Month/Year)</th>
                          <th scope="col">Claim</th>
                          <th scope="col">Details of Claim</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {claimDetails.map((row, index) => {
                          return (
                            <tr key={index}>
                              <td>{row.period}</td>
                              <td>{row.claim}</td>
                              <td>{row.detailsOfClaim}</td>
                              <td>{row.amount}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CommonDialog>
    </>
  );
};

export default BalanceConfirmationHistoryModal;
