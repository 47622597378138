import React, { useEffect, useState } from "react";
import Sidebar from "./common/sidebar_components/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import Header from "./common/header_components/header";
import { Outlet, useNavigate } from "react-router-dom";
import { CustomerCodeProvider } from "./context/CustomerCodeProvider";

const App = (props) => {
  const initialState = {
    ltr: true,
    divName: "RTL",
  };

  const [side, setSide] = useState(initialState);

  const ChangeRtl = (divName) => {
    if (divName === "RTL") {
      document.body.classList.add("rtl");
      setSide({ divName: "LTR" });
    } else {
      document.body.classList.remove("rtl");
      setSide({ divName: "RTL" });
    }
  };

  const timeout = 2 * 60 * 60 * 1000; //1 hour
  const history = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      goToLogout();
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout]);

  const goToLogout = () => {
    sessionStorage.clear();
    history(`${process.env.PUBLIC_URL}`);
  };

  return (
    <div>
      <div className="page-wrapper">
        <CustomerCodeProvider>
          <Header />
          <div className="page-body-wrapper">
            <Sidebar />
            <RightSidebar />
            <div className="page-body">
              <Outlet />
            </div>
            <Footer />
          </div>
        </CustomerCodeProvider>
      </div>
      {/* RTL OPTION HIDED IN UI */}
      {/* <div
				className="btn-light custom-theme"
				onClick={() => ChangeRtl(side.divName)}
			>
				{side.divName}
			</div> */}
    </div>
  );
};
export default App;
