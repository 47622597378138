import { Box, MenuItem } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import CommonLoader from "./commonLoader";
import CommonDialog from "./commonDialog";
import { useState } from "react";
import * as React from 'react';

const CommonExtModal = (props) => {
  const {
    open,
    onCancel,
    saveModalData,
    loading,
    setSaveModalData,
    reasonData,
    onSave,
  } = props;

  const [showRemarks, setShowRemarks] = useState(false); 

  const onInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "reason") {
      if (value === "Others") {
        setShowRemarks(true);
      } else {
        setShowRemarks(false);
        saveModalData.otherReason = "";
      }
    }
    saveModalData[name] = value;
    setSaveModalData({ ...saveModalData });
  };

  return (
    <Box className="btn-popup pull-right">
      <CommonDialog 
        open={open}
        onCancel={onCancel}
        onSave={onSave}
        primaryButtonLabel={"Save"}
        secondaryButtonLabel={"Cancel"}
        title={"Credit Extension"}
      >
        {loading ? <CommonLoader /> : null}

        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <TextField
              fullWidth
              id="outlined-select-currency"
              select
              label="No of Days Extension"
              defaultValue=""
              size="small"
              name="cdextensionDays"
              value={saveModalData.cdextensionDays}
              onChange={onInputChange}
            >
              {[...Array(10)].map((_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1} days
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item sm={12} xs={12}>
            <TextField
              fullWidth
              id="outlined-search"
              select
              label="Reason"
              name="reason"
              type="text"
              size="small"
              value={saveModalData.reason}
              onChange={onInputChange}
            >
              <MenuItem value="default" disabled>
                Select a reason
              </MenuItem>
              {reasonData.map((option) => (
                <MenuItem key={option.commonId} value={option.reason}>
                  {option.reason}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {showRemarks && (
            <Grid item sm={12} xs={12}>
              <TextField
                fullWidth
                id="outlined-search"
                label="Other Reason"
                name="otherReason"
                type="text"
                size="small"
                value={saveModalData.otherReason}
                onChange={onInputChange}
              />
            </Grid>
          )}
          <Grid item sm={12} xs={12}>
            <TextField
              fullWidth
              id="outlined-search"
              label="Additional Remarks"
              name="remarks"
              type="text"
              size="small"
              value={saveModalData.remarks}
              onChange={onInputChange}
            />
          </Grid>
        </Grid>
      </CommonDialog>
    </Box>
  );
};
export default CommonExtModal;
