import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardBody, Container, Row } from "reactstrap";

import { Button, Typography } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import {
  PersonSearch,
  Refresh,
  SimCardDownloadRounded,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonAccordion from "../../common/commonAccordion";
import CommonTable from "../../common/commonTable";
import {
  tds194RDeclarationTableData,
  tds194RDeclarationTableHeader,
} from "../../common/commonConstant";
import { useEffect } from "react";
import {
  getAllTds194RDeclaration,
  getAllRegionMasters,
  getAllStateMasters,
  resetTds194RDeclaration,
  searchTds194RDeclaration,
  exportToExcelTds194RDeclaration,
} from "../../api/service";
import CommonLoader from "../../common/commonLoader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useCustomerCode } from "../../context/CustomerCodeProvider";
import CommonDialog from "../../common/commonDialog";
import Tds194RDeclarationModal from "./tds194RDeclarationModal";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const Tds194RDeclaration = () => {
  const classes = useStyles();
  const history = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalRecord, setTotalRecord] = React.useState(0);

  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState([]);

  const [searchRegion, setSearchRegion] = useState([]);
  const [searchState, setSearchState] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [resetData, setResetData] = useState({});

  const filterInitialValues = {
    searchCustomerCode: "",
    searchRegion: "",
    searchState: "",
    searchStatus: "",
  };

  const [selectedItemBCStatus, setSelectedItemBCStatus] = useState(null);

  const BALANCE_CONFIRMATION_STATUS = [
    {
      value: "Pending",
      label: "Pending",
    },
    {
      value: "Agreed",
      label: "Agreed",
    },
    {
      value: "Disagreed",
      label: "Disagreed",
    },
  ];

  const [searchData, setSearchData] = useState(filterInitialValues);

  const { selectedCustomerCode } = useCustomerCode();
  const isAsmUser =
    JSON.parse(sessionStorage.getItem("userData")).groupId === 5;
  const isAdminUser =
    JSON.parse(sessionStorage.getItem("userData")).groupId === 1;

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {
    setLoading(true);
    getAllTds194RDeclaration(page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });

    getAllRegionMasters(page, rowsPerPage).then((res) => {
      setSearchRegion(res.response.responseObject);
    });

    getAllStateMasters(page, rowsPerPage).then((res) => {
      setSearchState(res.response.responseObject);
    });
  };

  const [open, setOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllTds194RDeclaration(newPage, rowsPerPage).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getAllTds194RDeclaration(0, event.target.value).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const filterData = () => {
    setLoading(true);
    searchData.searchCustomerCode = selectedCustomerCode;
    searchTds194RDeclaration(searchData, page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.responseMap.data);
      setTotalRecord(res.responseMap.totalCount);
    });
  };

  const exportData = () => {
    setLoading(true);
    exportToExcelTds194RDeclaration(
      selectedCustomerCode,
      moment(new Date()).format("DD_MM_YYYY_HH_MM_SS")
    ).then(() => {
      setLoading(false);
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const onOpenPopup = (data) => {
    setSelectedItemBCStatus(data.status);
    setModalData(data);
    setOpenModal(true);
  };

  const openResetModal = (data) => {
    setResetData(data);
    setShowResetPopup(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    loadListData();
  };

  const resetTds194RDeclarationData = () => {
    console.log(resetData);
    resetTds194RDeclaration(resetData).then((res) => {
      handleCloseResetModal();
      loadListData();
      toast.success("194R TDS Declaration resetted successfully!", {
        autoClose: 1000,
      });
    });
  };

  const handleCloseResetModal = () => {
    setShowResetPopup(false);
    setResetData({});
  };

  const isDistributorUser =
    JSON.parse(sessionStorage.getItem("userData")).groupId === 0;

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb
        title="194R TDS Declaration"
        parent={
          !isAsmUser &&
          !isDistributorUser && (
            <div className={classes.btnJust}>
              <Button
                color={"secondary"}
                variant="contained"
                type="button"
                className={`${classes.btnClass}`}
                // startIcon={<Add></Add>}
                onClick={() =>
                  history(
                    `${process.env.PUBLIC_URL}/master/tds194RDeclarationReport`
                  )
                }
              >
                Consolidate Report
              </Button>
            </div>
          )
        }
      />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <CommonAccordion accordionTitle={"Advanced Search"}>
            <Grid container columnSpacing={2} className={classes.muiGrid}>
              {!isDistributorUser && (
                <Grid item sm={3} xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-select-currency"
                    select
                    label="Region"
                    size="small"
                    name="searchRegion"
                    value={searchData.searchRegion}
                    onChange={onFilterInputChange}
                  >
                    {searchRegion.map((option) => (
                      <MenuItem
                        key={option.regionMasterId}
                        value={option.regionMasterId}
                      >
                        {option.regionName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              {!isDistributorUser && (
                <Grid item sm={3} xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-select-currency"
                    select
                    label="State"
                    size="small"
                    name="searchState"
                    value={searchData.searchState}
                    onChange={onFilterInputChange}
                  >
                    {searchState.map((option) => (
                      <MenuItem
                        key={option.stateMasterId}
                        value={option.stateMasterId}
                      >
                        {option.stateName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Status"
                  size="small"
                  name="searchStatus"
                  value={searchData.searchStatus}
                  onChange={onFilterInputChange}
                >
                  {BALANCE_CONFIRMATION_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                sm={3}
                xs={12}
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => filterData()}
                >
                  <PersonSearch></PersonSearch>
                </Button>
                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                    setSearchData(filterInitialValues);
                    loadListData();
                  }}
                >
                  <Refresh></Refresh>
                </Button>
                {!isDistributorUser && (
                  <Button
                    color="primary"
                    sx={{ marginLeft: 1 }}
                    variant="contained"
                    type="button"
                    className={classes.btnClass}
                    onClick={() => {
                      exportData();
                    }}
                  >
                    <SimCardDownloadRounded></SimCardDownloadRounded>
                  </Button>
                )}
              </Grid>
            </Grid>
          </CommonAccordion>
          <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  editMode={isDistributorUser}
                  tableData={data}
                  tableHeaders={tds194RDeclarationTableHeader}
                  tableColumns={tds194RDeclarationTableData}
                  editId={"tdsDeclarationId"}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={totalRecord}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                  showPdf={true}
                  from={"194R TDS Declaration"}
                  onOpenModal={onOpenPopup}
                  showReset={isAdminUser}
                  openResetModal={openResetModal}
                  isTDS194Declaration
                />
              </div>
            </CardBody>
          </Card>
        </Row>

        <Tds194RDeclarationModal
          open={openModal}
          handleClose={handleCloseModal}
          setData={setModalData}
          data={modalData}
          showSubmitButton={selectedItemBCStatus === "Pending"}
        />
        <CommonDialog
          open={showResetPopup}
          title="Reset"
          onCancel={() => {
            handleCloseResetModal();
          }}
          primaryButtonLabel={"Yes"}
          secondaryButtonLabel={"No"}
          onSave={() => {
            resetTds194RDeclarationData();
          }}
        >
          <Typography variant="body1">
            Do you want to reset TDS 194R declaration for this distributor?
          </Typography>
        </CommonDialog>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default Tds194RDeclaration;
