import axios from "axios";

//const url = "http://localhost:8090/service/";
//const url = "http://13.126.15.195:8080/modenik/service/";
const url = "https://modeniktransparency.in/modenik/service/";

const instance = axios.create({
  baseURL: url,
});

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log("Error Interceptor =>", err.response);
    // alert("Kindly try again in some time.")
    // alert(err.response.data);
    return err;
  }
);

export default instance;
