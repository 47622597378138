import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  exportToExcelUserLog,
  getLoggedInUserDetails,
  loadGroupData,
  searchLoggedInUserDetails,
} from "../../api/service";
import CommonTable from "../../common/commonTable";
import {
  userLogTableData,
  userLogTableHeader,
} from "../../common/commonConstant";
import CommonLoader from "../../common/commonLoader";
import CommonAccordion from "../../common/commonAccordion";
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import {
  PersonSearch,
  Refresh,
  SimCardDownloadRounded,
} from "@mui/icons-material";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const UserLog = () => {
  const classes = useStyles();

  const filterInitialValues = {
    searchGroup: -1,
    searchFromDate: "",
    searchToDate: "",
  };
  const [searchData, setSearchData] = useState(filterInitialValues);
  const [groupData, setGroupData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadListData(page, rowsPerPage);
    loadGroupList();
  }, []);

  const loadListData = (page, rowsPerPage) => {
    setLoading(true);
    getLoggedInUserDetails(page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const loadGroupList = () => {
    loadGroupData(page, 100).then((res) => {
      setGroupData(res.response.responseObject);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    loadListData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    loadListData(0, event.target.value);
  };

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  const filterData = () => {
    setLoading(true);
    searchLoggedInUserDetails(searchData, page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.responseMap.data);
    });
  };

  const exportData = () => {
    setLoading(true);
    exportToExcelUserLog(
      searchData,
      moment(new Date()).format("DD_MM_YYYY_HH_MM_SS")
    ).then(() => {
      setLoading(false);
    });
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb title="User Log" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <CommonAccordion accordionTitle={"Advanced Search"}>
            <Grid container columnSpacing={2} className={classes.muiGrid}>
              <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Group"
                  size="small"
                  defaultValue="Y"
                  name="searchGroup"
                  value={searchData.searchGroup}
                  onChange={onFilterInputChange}
                >
                  <MenuItem key={-1} value={-1}>
                    {"ALL"}
                  </MenuItem>
                  <MenuItem key={0} value={0}>
                    {"DISTRIBUTOR"}
                  </MenuItem>
                  {groupData.map((option) => (
                    <MenuItem key={option.groupId} value={option.groupId}>
                      {option.groupName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  label="From Date"
                  size="small"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="fullWidth"
                  name="searchFromDate"
                  value={searchData.searchFromDate}
                  onChange={onFilterInputChange}
                />
              </Grid>

              <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  label="To Date"
                  size="small"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="fullWidth"
                  name="searchToDate"
                  value={searchData.searchToDate}
                  onChange={onFilterInputChange}
                />
              </Grid>

              <Grid
                item
                sm={3}
                xs={12}
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => filterData()}
                >
                  <PersonSearch></PersonSearch>
                </Button>
                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                    setSearchData(filterInitialValues);
                    loadListData(0, 25);
                  }}
                >
                  <Refresh></Refresh>
                </Button>

                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                    exportData();
                  }}
                >
                  <SimCardDownloadRounded></SimCardDownloadRounded>
                </Button>
              </Grid>
            </Grid>
          </CommonAccordion>

          <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  tableData={data}
                  tableHeaders={userLogTableHeader}
                  tableColumns={userLogTableData}
                  editId={""}
                  editMode={false}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={totalRecord}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                />
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default UserLog;
