import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, TextField } from "@mui/material";
import CommonDialog from "../../common/commonDialog";
import CommonLoader from "../../common/commonLoader";

const useStyles = makeStyles((theme) => ({
  checkLabelFont: {
    marginLeft: "12px!important",
    color: "#292929!important",
    fontSize: "1rem!important",
  },
  checkFont: {
    display: "flex",
    justifyContent: "start",
    marginLeft: "0px!important",
  },
}));

const RegionModal = (props) => {
  const classes = useStyles();

  const { open, onCancel, onSave, regionData, setRegionData,loading } = props;
 
  const onInputChange = (event) => {
    const { name, value } = event.target;
    regionData[name] = value;
    setRegionData({ ...regionData });
  };

  return (
    <CommonDialog
      onCancel={onCancel}
      onSave={() => onSave()}
      open={open}
      primaryButtonLabel={"Save"}
      secondaryButtonLabel={"Cancel"}
      title={"Region Master"}
    >
      {loading ? <CommonLoader /> : null}

      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Region Name"
            name="regionName"
            type="text"
            size="small"
            value={regionData.regionName}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Region Description"
            name="remarks"
            multiline
            rows={2}
            value={regionData.remarks}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className={classes.checkLabelFont}
            >
              Status
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="status"
              value={regionData.status}
              onChange={onInputChange}
            >
              <FormControlLabel
                value="Y"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    fontSize={15}
                    sx={{ color: "#292929" }}
                  >
                    ACTIVE
                  </Typography>
                }
                className={classes.checkFont}
              />
              <FormControlLabel
                value="N"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    fontSize={15}
                    sx={{ color: "#292929" }}
                  >
                    INACTIVE
                  </Typography>
                }
                className={classes.checkFont}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </CommonDialog>
  );
};
export default RegionModal;
