import {
  Box,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import CommonLoader from "./commonLoader";
import CommonDialog from "./commonDialog";
import { Card, CardBody } from "reactstrap";


const useStyles = makeStyles(() => ({
  checkLabelFont: {
    marginLeft: "12px!important",
    color: "#292929!important",
    fontSize: "1rem!important",
  },
  checkFont: {
    display: "flex",
    justifyContent: "start",
    marginLeft: "0px!important",
  },
}));

const CommonModal = (props) => {
  const classes = useStyles();

  const { open, onCancel, modalData, tableModalHeaders, loading } = props;

  return (
    <Box className="btn-popup pull-right">
    <CommonDialog maxWidth="md"
      open={open}
      onCancel={onCancel}
      title={"Detailed View"}
    >
      {loading ? <CommonLoader /> : null}

      <Card style={{margin:0}}>
        <Paper square={false} elevation={3} style={{ margin: 3 }}>
          <Card style={{margin:0}}>
            <CardBody style={{padding:20}}>
              <Grid container spacing={2}>
                {tableModalHeaders.map((row) => {
                  let columnName = row.column;
                  return (
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 14 }} color={"black"} fontWeight={700}>{row.name}</Typography>
                      <Typography style={{ fontSize: 12,color:'#222222'}}>{modalData[columnName]}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </CardBody>
          </Card>
        </Paper>

      </Card>

    </CommonDialog>
    </Box>
  );
};
export default CommonModal;
