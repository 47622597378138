import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  btnClass1: {
    padding: "0.4rem 1.5rem!important",
    fontWeight: 600,
  },
  muiModalFoot: {
    padding: theme.spacing(3),
    margin: theme.spacing(1),
  },
  forModalTitle: {
    padding: theme.spacing(2, 0),
    marginLeft: theme.spacing(2.2),
    textTransform: "capitalize",
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    marginTop: "0vh",
  },

  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },

  "& label.Mui-focused": {
    color: "#292929!important",
  },
  "& .MuiDialog-paper": {
    borderRadius: 10,
  },
  // "& .MuiOutlinedInput-root": {
  //   "&.Mui-focused fieldset": {
  //     borderColor: "#f91e26!important",
  //   },
  // },
}));

const CommonDialog = (props) => {
  const classes = useStyles();

  const {
    children,
    onCancel,
    onSave,
    open,
    primaryButtonLabel = "",
    secondaryButtonLabel = "",
    title,
    maxWidth = "sm",


  } = props;

  return (
    <Box className="btn-popup pull-right">
      <BootstrapDialog
        fullWidth
        maxWidth={maxWidth}
        onClose={onCancel}
        open={open}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title" className={classes.forModalTitle}>
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>{children}</DialogContent>
       
          <DialogActions className={classes.muiModalFoot}>
             {primaryButtonLabel != "" ? (
            <Button
              type="button"
              color={"secondary"}
              className={classes.btnClass1}
              variant="contained"
              onClick={onSave}
            >
              {primaryButtonLabel}
            </Button>
            ) : null}
            
            {secondaryButtonLabel != "" ? (
            <Button
              type="button"
              color={"primary"}
              className={classes.btnClass1}
              variant="contained"
              onClick={onCancel}
            >
              {secondaryButtonLabel}
            </Button>
            ) : null}
      

          </DialogActions>
        
      </BootstrapDialog>
    </Box>
  );
};
export default CommonDialog;
