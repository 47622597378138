import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
	   backgroundColor: '#f8f8f9',//theme.palette.common.primary,
	//   color: theme.palette.common.white,
	  padding:'8px',
	//   border: '5px solid white',
	  fontWeight:700
	},
	[`&.${tableCellClasses.body}`]: {
	  fontSize: 13,
	  padding:1,
	  border: 'none',
    borderWidth: '0px 0.5px 0px 0px'
	},
  }));
  

  export default StyledTableCell;