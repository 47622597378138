export const userTableHeader = [
  "Name",
  "UserName",
  "Mobile No",
  "Email",
  "Group",
  "Reporting Group",
  "Reporting To",
  "Status",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const userTableData = [
  "name",
  "username",
  "mobileNo",
  "emailId",
  "groupName",
  "reportingGroupName",
  "reportingToName",
  "status",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];
export const groupTableHeader = [
  "GroupName",
  "Status",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const groupTableData = [
  "groupName",
  "status",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const regionMasterTableHeader = [
  "Name",
  "Description",
  "Status",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const regionMasterTableData = [
  "regionName",
  "remarks",
  "status",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const stateMasterTableHeader = [
  "Name",
  "Description",
  "Status",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const stateMasterTableData = [
  "stateName",
  "remarks",
  "status",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];
export const divisionMasterTableHeader = [
  "Division Name",
  "Division Code",
  "Description",
  "Status",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const divisionMasterTableData = [
  "divisionName",
  "divisionCode",
  "remarks",
  "status",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];
export const distributorTableHeader = [
  "Region",
  // "Division",
  // "Main Agent",
  // "Sub Agent",
  "SAP Party Code",
  "Main Party",
  "Sub Party",
  "City",
  "State",
  "Address",
  "Mail Id",
  "Mobile No",
  // "BM",
  // "BSM",
  // "ASM",
  // "Co-ordinator 1",
  // "Co-ordinator 2",
  "GST No",
  "PAN No",
  "Status",
  "Dixcy Virtual Account",
  "Maximus Virtual Account",
  "Levis Virtual Account",
  "Thermal Virtual Account",
  "Enamor Virtual Account",
  "Ifsc Code",
  "Client Code",
  "Beneficiary Name",
  "Bank Name",
  "Bank Branch",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const distributorTableData = [
  "regionName",
  // "divisionName",
  // "mainAgent",
  // "subAgent",
  "sapPartyCode",
  "mainParty",
  "subParty",
  "city",
  "stateName",
  "address",
  "mailId",
  "mobileNo",
  // "bm",
  // "bsm",
  // "asm",
  // "coOrdinatorOne",
  // "coOrdinatorTwo",
  "gstNo",
  "panNo",
  "status",
  "dixcyVirtualAc",
  "maximusVirtualAc",
  "levisVirtualAc",
  "thermalVirtualAc",
  "enamorVirtualAc",
  "ifscCode",
  "clientCode",
  "beneficiaryName",
  "bankName",
  "bankBranch",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const outstandingBillTableHeader = [
  "Plant Name",
  "Customer Code",
  "Main Agent",
  "Customer Name",
  "Invoice number",
  "Invoice Date",
  "Baseline(LR) Date",
  "Extended Baseline(LR) Date",
  "Invoice Amount",
  "Adjusted Amount",
  "Balance Amount",
  "Aging DAYS",
  "CD Due Date",
  "First CD days",
  "CDExtensionDays",
  "CD Amount",
  "Division",
];
export const outstandingBillTableData = [
  "plantName",
  "customerCode",
  "mainAgent",
  "customerName",
  "invoiceNumber",
  "documentDate",
  "baselineDate",
  "newBaselineDate",
  "invoiceAmount",
  "adjustedAMOUNT",
  "balanceAmount",
  "agingDAYS",
  "cddueDate",
  "firstCDDays",
  "cdextensionDays",
  "cdamount",
  "division",
];

export const outstandingBillConsolidateTableHeader = [
  "Particulars",
  "Invoice Amount",
  "Adjusted Amount",
  "Balance Amount",
];
export const outstandingBillConsolidateTableData = [
  "plantName",
  "invoiceAmount",
  "adjustedAMOUNT",
  "balanceAmount",
];

export const openDebitTableHeader = [
  "Customer Code",
  "Customer Name",
  "Main Agent",
  "SD Billing Doc No",
  "SD Billing Date",
  "SD Bill Amount",
  "Document Type",
  "Debit Note Doc No",
  "Debit Note Doc Date",
  "Debit Note Amount",
  "Adjusted Amount",
  "Balance Debit",
  "Ageing Days",
  "Short Text",
  "Long Text",
  "LR Date",
  "Actual Due Date",
  "BR Date",
  "Paid days",
  "Delay Days(Post Due Date)",
  "Expenses GL Description",
  "Division",
];
export const openDebitTableData = [
  "customerCode",
  "customerName",
  "mainAgent",
  "sdBillingDocNo",
  "sdBillingDate",
  "sdBillAmt",
  "documentType",
  "debitNoteDocNo",
  "debitNoteDocDate",
  "debitNoteAmt",
  "adjustedAmt",
  "balanceDebit",
  "agingDays",
  "shortText",
  "longText",
  "lrDate",
  "actualDueDate",
  "brDate",
  "paidDays",
  "delayDays",
  "expensesGlDesc",
  "division",
];

export const openCreditTableHeader = [
  "Customer Code",
  "Customer Name",
  "Main Agent",
  "Document Type",
  "Document Number",
  "Document Date",
  "CN Amount",
  "Adjusted Amount",
  "Balance CN Amount",
  "Ageing Days",
  "Short Text",
  "Long Text",
  "Expenses GL Description",
  "Division",
];
export const openCreditTableData = [
  "customerCode",
  "customerName",
  "mainAgent",
  "documentType",
  "documentNumber",
  "documentDate",
  "cnAmt",
  "adjustedAmt",
  "balanceCnAmt",
  "agingDays",
  "shortText",
  "longText",
  "expensesGlDesc",
  "division",
];

export const adjustmentReportTableHeader = [
  "Customer Code",
  "Name",
  "Invoice No",
  "Document Number",
  "Document Type",

  "Posting Date",
  "Baseline Date",
  "NDD Without Grace Date",
  "Total Invoice Amt",
  "Division",
  "Description",

  "Clearing Date",
  "CD Applicable Amount",
  "To be received",
  "Document No",

  "Description",
  "Receipt/CN",
  "Balance/Excess",
  "Remain Document",
  "Remain Amts",
  "Receipt/ CN Date",
  "Paid Day",
  "CD Credit",
  "CD not Eligible",
  "Pay Term",
  "Delay days",
];
export const adjustmentReportTableData = [
  "customerCode",
  "name",
  "invoiceNo",
  "invoiceDocNumber",
  "documentType",

  "postingDate",
  "baselineDate",
  "nddwithoutGraceDate",
  "totalInvoiceAmt",
  "division",

  "divisionDescription",
  "clearingDate",
  "cdApplicableAmount",
  "toBeReceived",
  "documentNo",
  "description",
  "receiptCn",
  "balanceExcess",
  "remainDocument",
  "remainAmts",
  "receiptCNDate",
  "paidDay",
  "cdCredit",
  "cdNotEligible",
  "payTerm",
  "delaydays",
];

export const adjustmentReportConsolidateTableHeader = [
  "Name",
  "Customer Code",
  "Balance Excess",
];
export const adjustmentReportConsolidateTableData = [
  "name",
  "customerCode",
  "balanceExcess",
];

export const openDebitConsolidateTableHeader = [
  "Division",
  "Customer Code",
  "Balance Debit",
];
export const openDebitConsolidateTableData = [
  "division",
  "customerCode",
  "balanceDebit",
];

export const openCreditConsolidateTableHeader = [
  "Division",
  "Customer Code",
  "CN Amount",
  "Balance CN AMOUNT",
];
export const openCreditConsolidateTableData = [
  "division",
  "customerCode",
  "cnAmt",
  "balanceCnAmt",
];

export const creditExtensionTableHeader = [
  "Customer Code",
  "Distributor Name",
  "Credit Extension Days",
  "Reason",
  "Other Reason",
  "Remarks",
  "Status",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const creditExtensionTableData = [
  "customerCode",
  "distributorName",
  "cdextensionDays",
  "reason",
  "otherReason",
  "remarks",
  "approvalStatus",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const balanceConfirmationTableHeader = [
  "Financial Year",
  "Quarter",
  "Customer Code",
  "Name",
  "Closing Balance",
  "Date",
  "Type",
  "Date of BC Received",
  "Region",
  "State",
  "Status",
  "Customer Balance",
  "Customer Balance Type",
  "Revision No",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const balanceConfirmationTableData = [
  "financialYear",
  "quarter",
  "customerCode",
  "name",
  "closingBalance",
  "balanceConfirmationDate",
  "balanceConfirmationType",
  "agreedDate",
  "regionName",
  "stateName",

  "balanceConfirmationStatus",
  "customerBalanceAmount",
  "customerBalanceType",
  "revisionNo",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const policyMasterTableHeader = [
  "Policy Name",
  "Description",
  "Status",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const policyMasterTableData = [
  "policyName",
  "description",
  "status",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const ndcTableHeader = [
  "Customer Code",
  "Period",
  "Claim",
  "Details Of Claim",
  "Amount",
  "Status",
  "Remarks",
  "Satisfied By Customer",

  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const ndcTableData = [
  "customerCode",
  "period",
  "claim",
  "detailsOfClaim",
  "amount",
  "status",
  "remarks",
  "satisfiedByCustomer",

  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const reasonMasterTableHeader = [
  "Reason",
  "Module Type",
  "Status",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const reasonMasterTableData = [
  "reason",
  "moduleType",
  "status",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const addressMasterTableHeader = [
  "Plant Code",
  "Plant Name",
  "Location",
  "GST",
  "Address",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const addressMasterTableData = [
  "plantCode",
  "plantName",
  "location",
  "gst",
  "address",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const distributorMappingTableHeader = [
  "Division",
  "SAP Party Code",
  "Main Agent",
  "Sub Agent",
  "BM Name",
  "BM Email",
  "Co-ordinator 1 Name",
  "Co-ordinator 1 Email",
  "BCE Name",
  "BCE Email",
  "BCE Mobile No",
  "BCM Name",
  "BCM Email",
  "BCM Mobile No",
  "Pay Term1 Days",
  "Pay Term1 %",
  "Pay Term2 Days (Base)",
  "Pay Term2 % (Base)",
  "Pay Term3 Days",
  "Pay Term3 %",
  "Pay Term4 Days",
  "Pay Term4 %",
  "Payment Terms",
  /* "BM",
  "BSM",
  "ASM",
  "Co-ordinator 1",
  "Co-ordinator 2", */

  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const distributorMappingTableData = [
  "divisionName",
  "sapPartyCode",
  "mainAgent",
  "subAgent",
  "bmName",
  "bmEmail",
  //"bm",
  //"bsm",
  //"asm",
  "coOrdinatorOneName",
  "coOrdinatorOneEmail",
  "bceName",
  "bceEmail",
  "bceMobileNo",
  "bcmName",
  "bcmEmail",
  "bcmMobileNo",
  "paymentTerms1Days",
  "paymentTerms1Percent",
  "paymentTerms2Days",
  "paymentTerms2Percent",
  "paymentTerms3Days",
  "paymentTerms3Percent",
  "paymentTerms4Days",
  "paymentTerms4Percent",
  "paymentTerms",

  //"coOrdinatorOne",
  //"coOrdinatorTwo",

  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const balanceConfirmationReportTableHeader = [
  "Region",
  "Total Customer",
  "Total Closing Balance",
  "Total Customer Balance",
  "Total Difference",
  "Agreed Count",
  "Agreed %",
  "Agreed Value %",
  "Disgreed Count",
  "Disgreed %",
  //"Disagreed Value %",
  "Balance Confirmed %",
  "Balance Confirmed Value %",
  // "Created By",
  // "Created On",
  // "Updated By",
  // "Updated On",
];
export const balanceConfirmationReportTableData = [
  "regionName",
  "totalCount",
  "totalClosingBalance",
  "totalCustomerBalance",
  "disagreedDifference",
  "agreedCount",
  "agreedPercentage",
  "agreedValuePercentage",
  "disagreedCount",
  "disagreedPercentage",
  //"disagreedValuePercentage",
  "percentage",
  "valuePercentage",
  // "createdBy",
  // "createdDate",
  // "updatedBy",
  // "updatedDate",
];

export const userLogTableHeader = [
  "Name",
  "User Name",
  "Group",
  "Login In Time",
];
export const userLogTableData = [
  "name",
  "loginUserName",
  "groupName",
  "loginTime",
];

export const tds194RDeclarationTableHeader = [
  //"Financial Year",
  "Customer Code",
  "Name",
  "Region",
  "State",
  "Approval Date",
  "Status",
  "Created By",
  "Created On",
  "Updated By",
  "Updated On",
];
export const tds194RDeclarationTableData = [
  //"financialYear",
  "customerCode",
  "name",
  "regionName",
  "stateName",
  "agreedDate",
  "status",
  "createdBy",
  "createdDate",
  "updatedBy",
  "updatedDate",
];

export const tds194RDeclarationReportTableHeader = [
  "Region",
  "Total Customer",
  "Agreed Count",
  "Disgreed Count",
];
export const tds194RDeclarationReportTableData = [
  "regionName",
  "totalCount",
  "agreedCount",
  "disagreedCount",
];
