import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CommonDialog from "../../common/commonDialog";
import { Link } from "react-router-dom";
import moment from "moment";
import { saveTds194RDeclaration } from "../../api/service";

const useStyles = makeStyles((theme) => ({
  checkLabelFont: {
    marginLeft: "12px!important",
    color: "#292929!important",
    fontSize: "1rem!important",
  },
}));

const Tds194RDeclarationModal = (props) => {
  const classes = useStyles();
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);

  const [openPinDialog, setOpenPinDialog] = useState(false);

  const [verification, setVerification] = useState("PIN");
  const {
    open,
    onCancel,
    handleClose,
    onSave,
    data,
    setData,
    showSubmitButton,
  } = props;

  const onInputChange = (event) => {
    const { name, value } = event.target;
    data[name] = value;
    if (name === "status") {
      if (value === "Disagreed") {
        //Disagreed
      } else {
        //agreed
      }
    }
    setData({ ...data });
  };

  const pinPopUp = () => {
    setVerification("PIN");
  };

  const otpPopUp = () => {
    setVerification("OTP");
  };

  const handleVerificationDialogClose = () => {
    setOpenVerificationDialog(false);
  };

  const handlePinDialogClose = () => {
    setOpenPinDialog(false);
  };

  const handleSubmit = () => {
    data.agreedDate = moment(new Date()).format("DD-MM-YYYY");
    saveTds194RDeclaration(data).then((res) => {
      setOpenPinDialog(false);
      handleClose();
    });
    /*  if (data.balanceConfirmationStatus === "Agree") {
      setOpenVerificationDialog(true);
      // handleClose();
    } else {
      setOpenVerificationDialog(true);
    } */
  };

  const handleSubmitAgree = () => {
    setOpenVerificationDialog(false);
    setOpenPinDialog(false);

    if (verification === "PIN") {
      setOpenPinDialog(true);
      // handleVerificationDialogClose();
    } else {
      setOpenPinDialog(true);
    }
  };

  return (
    <>
      <CommonDialog
        onCancel={handleClose}
        onSave={() => {
          if (showSubmitButton) {
            if (data.status !== "Pending") {
              setOpenVerificationDialog(true);
            }
          } else {
            alert(
              "Approval Process already completed for this 194R TDS Declaration"
            );
          }
        }}
        open={open}
        primaryButtonLabel={"Submit"}
        title={"194R TDS Declaration"}
        maxWidth="md"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <div>Sub: Compliance declaration u/s 194R</div>
            <div>
              Ref: 10% TDS on Retailer non-monetary schemes (Ex:
              Article/Gold/Silver/Voucher/Gift/Leisure Trip)
            </div>
            <br />
            {data.status !== "Disagreed" ? (
              <div>
                This is to certify that all the retailer non-monetary schemes
                launched by <b>Modenik Lifestyle Pvt Ltd</b>. are being duly
                passed to the retailers, as per their defined scheme
                eligibility. This is to also declare that we are compliant with
                the rules and regulations of Section 194R under the Income Tax
                Act, 1961.
              </div>
            ) : (
              <div>
                I agree to TDS as applicable u/s 194R and a debit note can be
                raised in my account (to be recovered through payments or credit
                notes) for the amount of TDS paid to the government on my behalf
                by <b>Modenik Lifestyle Pvt. Ltd</b>.
              </div>
            )}
            <hr />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="status"
                value={data.status}
                onChange={onInputChange}
              >
                <FormControlLabel
                  value="Agreed"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#f91e26",
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 23,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body1"
                      fontSize={15}
                      sx={{ color: "#292929" }}
                    >
                      Agree
                    </Typography>
                  }
                  className={classes.checkFont}
                />
                <FormControlLabel
                  value="Disagreed"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#f91e26",
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 23,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body1"
                      fontSize={15}
                      sx={{ color: "#292929" }}
                    >
                      Disagree
                    </Typography>
                  }
                  className={classes.checkFont}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography color="red">
              Note: This declaration is valid until revoked or amended.
              Revocation or amendment can only be done in Quarter 1 i.e. between
              April 1 to June 30.{" "}
            </Typography>
          </Grid>
        </Grid>
      </CommonDialog>

      <CommonDialog
        open={openVerificationDialog}
        title="Digital Verification"
        onCancel={() => setOpenVerificationDialog(false)}
        primaryButtonLabel={"Next"}
        onSave={handleSubmitAgree}
      >
        <Typography variant="body1">
          Choose option for digital verification
        </Typography>

        <Grid item style={{ margin: "auto" }}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="verification"
              name="verification"
              value={verification}
              onChange={(e) => setVerification(e.target.value)}
              style={{ display: "block" }}
            >
              <FormControlLabel
                value="PIN"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label="PIN"
                className={classes.checkLabelFont}
                onClick={pinPopUp}
              />
              <FormControlLabel
                value="OTP"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label="OTP"
                className={classes.checkLabelFont}
                onClick={otpPopUp}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </CommonDialog>

      <CommonDialog
        open={openPinDialog}
        title="Digital Verification"
        onCancel={() => setOpenPinDialog(false)}
        primaryButtonLabel={"Submit"}
        onSave={handleSubmit}
      >
        <Typography variant="body1">
          By entering PIN/OTP you are confirming our balance mentioned
        </Typography>

        <Grid container spacing={2} style={{ margin: "5px 0", width: "80%" }}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="outlined-search"
              label="PIN"
              name="PIN"
              type="text"
              size="small"
            />
          </Grid>
        </Grid>
      </CommonDialog>
    </>
  );
};

export default Tds194RDeclarationModal;
