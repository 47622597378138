import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { getAllOpenDebitHistory } from "../../api/service";

import CommonLoader from "../../common/commonLoader";
import Breadcrumb from "../../common/breadcrumb";
import CommonCardDesign from "../../common/commonCardDesign";
import {
  CardOpenDebitValue,
  openDebitTableData1,
  openDebitTableHeader1,
} from "../../common/commonCardConstant";
import { useCustomerCode } from "../../context/CustomerCodeProvider";

const useStyles = makeStyles((theme) => ({
  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },
}));

const OpenDebitHistory = () => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { selectedCustomerCode, selectedCustomerName } = useCustomerCode();

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {
    setLoading(true);
    getAllOpenDebitHistory(selectedCustomerCode).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb title="Open Debit History" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <CardBody className={classes.cardbody}>
            <div>
              <CommonCardDesign
                tableData={data}
                tableHeaders={openDebitTableHeader1}
                tableColumns={openDebitTableData1}
                tableModal={CardOpenDebitValue}
                rowsPerPageOptions={[25, 50, 100]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ float: "right" }}
                displayPagination={false}
                showPdf={true}
                from={"Open Debit History"}
                maxHeight={600}
              />
            </div>
          </CardBody>
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default OpenDebitHistory;
