import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Card, CardBody, Container, Row } from "reactstrap";

import { ToastContainer } from "react-toastify";

import { makeStyles } from "@mui/styles";
import CommonTable from "../../common/commonTable";
import {
  balanceConfirmationTableData,
  balanceConfirmationTableHeader,
} from "../../common/commonConstant";
import { useEffect } from "react";
import { getAllBalanceConfirmationHistory } from "../../api/service";
import CommonLoader from "../../common/commonLoader";
import { useLocation, useNavigate } from "react-router-dom";
import BalanceConfirmationHistoryModal from "./balanceConfirmationHistoryModal";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const BalanceConfirmationHistory = () => {
  const classes = useStyles();
  const history = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalRecord, setTotalRecord] = React.useState(0);

  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState([]);

  const [loading, setLoading] = useState(false);

  const isAdminUser =
    JSON.parse(sessionStorage.getItem("userData")).groupId === 1;

  const location = useLocation();

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {
    const balanceConfirmationId = location?.state?.balanceConfirmationIdParam;
    if (balanceConfirmationId) {
      setLoading(true);
      getAllBalanceConfirmationHistory(balanceConfirmationId).then((res) => {
        setLoading(false);
        setData(res.response.responseObject);
      });
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const onOpenPopup = (data) => {
    setModalData(data);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb title="Balance Confirmation History" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  editMode={isAdminUser}
                  tableData={data}
                  tableHeaders={balanceConfirmationTableHeader}
                  tableColumns={balanceConfirmationTableData}
                  editId={"balanceConfirmationId"}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={totalRecord}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  sx={{ float: "right" }}
                  showPdf={false}
                  from={"Balance Confirmation History"}
                  onOpenModal={onOpenPopup}
                  showFileDownload={true}
                  showReset={false}
                  displayPagination={false}
                />
              </div>
            </CardBody>
          </Card>
        </Row>

        <BalanceConfirmationHistoryModal
          open={openModal}
          handleClose={handleCloseModal}
          data={modalData}
        />
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default BalanceConfirmationHistory;
