import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import { Button } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { PersonSearch, Add, Refresh } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import RegionModal from "./regionModal";
import CommonAccordion from "../../common/commonAccordion";
import {
  getAllRegionMasters,
  saveOrUpdateRegionMaster,
  searchRegionData,
} from "../../api/service";
import {
  regionMasterTableData,
  regionMasterTableHeader,
} from "../../common/commonConstant";
import CommonTable from "../../common/commonTable";
import CommonLoader from "../../common/commonLoader";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const status = [
  {
    value: "Y",
    label: "Active",
  },
  {
    value: "N",
    label: "In-Active",
  },
];

const RegionMaster = () => {
  const classes = useStyles();
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const initialValues = {
    regionMasterId: null,
    regionName: "",
    remarks: "",
    status: "Y",
  };

  const [data, setData] = useState([]);
  const [regionData, setRegionData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const filterInitialValues = {
    searchRegionName: "",
    searchStatus: "",
  };
  const [searchData, setSearchData] = useState(filterInitialValues);

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {
    setLoading(true);
    getAllRegionMasters(page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const [open, setOpen] = useState(false);

  const onOpenModal = (data = initialValues) => {
    setRegionData(data);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllRegionMasters(newPage, rowsPerPage).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getAllRegionMasters(0, event.target.value).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const onHandleSave = () => {
    setLoading(true);
    saveOrUpdateRegionMaster(regionData).then((res) => {
      if (regionData.regionMasterId) {
        toast.success("Successfully Updated !", { autoClose: 1000 });
      } else {
        toast.success("Successfully Created !", { autoClose: 1000 });
      }
      setLoading(false);
      setOpen(false);
      setRegionData(initialValues);
      loadListData();
    });
  };

  const filterData = () => {
    searchRegionData(searchData, page, rowsPerPage).then((res) => {
      setData(res.responseMap.data);
    });
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}

      <Breadcrumb
        title="Region Master"
        parent={
          <div className={classes.btnJust}>
            <Button
              color={"secondary"}
              variant="contained"
              type="button"
              className={`${classes.btnClass}`}
              startIcon={<Add></Add>}
              onClick={() => onOpenModal()}
            >
              Add Region
            </Button>
          </div>
        }
      />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <CommonAccordion accordionTitle={"Advanced Search"}>
            <Grid container columnSpacing={2} className={classes.muiGrid}>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="fullWidth"
                  name="searchRegionName"
                  value={searchData.searchRegionName}
                  onChange={onFilterInputChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Status"
                  size="small"
                  name="searchStatus"
                  value={searchData.searchStatus}
                  onChange={onFilterInputChange}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                sm={4}
                xs={12}
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => filterData()}
                >
                  <PersonSearch></PersonSearch>
                </Button>
                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                    setSearchData(filterInitialValues);
                    loadListData();
                  }}
                >
                  <Refresh></Refresh>
                </Button>
              </Grid>
            </Grid>
          </CommonAccordion>
          <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  tableData={data}
                  tableHeaders={regionMasterTableHeader}
                  tableColumns={regionMasterTableData}
                  editId={"regionMasterId"}
                  editMode={true}
                  onOpenModal={onOpenModal}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={totalRecord}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                />
              </div>
            </CardBody>
          </Card>
        </Row>

        <RegionModal
          open={open}
          onCancel={() => {
            setOpen(false);
            setRegionData(initialValues);
          }}
          onSave={onHandleSave}
          regionData={regionData}
          setRegionData={setRegionData}
        />
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default RegionMaster;
