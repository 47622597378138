import { makeStyles } from "@mui/styles";
import { Grid, TextField } from "@mui/material";
import CommonDialog from "../../common/commonDialog";

const AddressModal = (props) => {

  const { open, onCancel, onSave, addressData, setAddressData } = props;

  const onInputChange = (event) => {
    const { name, value } = event.target;
    addressData[name] = value;
    setAddressData({ ...addressData });
  };

  return (
    <CommonDialog
      onCancel={onCancel}
      onSave={onSave}
      open={open}
      primaryButtonLabel={"Save"}
      secondaryButtonLabel={"Cancel"}
      title={"Address Master"}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Plant Code"
            name="plantCode"
            type="text"
            size="small"
            value={addressData.plantCode}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Plant Name"
            name="plantName"
            type="text"
            size="small"
            value={addressData.plantName}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Location"
            name="location"
            type="text"
            size="small"
            value={addressData.location}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="GST"
            name="gst"
            type="text"
            size="small"
            value={addressData.gst}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Address"
            name="address"
            multiline
            rows={2}
            value={addressData.address}
            onChange={onInputChange}
          />
        </Grid>
      </Grid>
    </CommonDialog>
  );
};
export default AddressModal;
