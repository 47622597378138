import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';


  const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
	//   backgroundColor: theme.palette.action.hover,
	borderBottom:'2px solid grey!important'
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		// borderBottom:'0.5px solid #ef4123!important'
	  //border: 0,
	},
  }));

  export default StyledTableRow;