import React, { Fragment, useState, useEffect } from "react";
import { Button, Card } from "reactstrap";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  DateRange,
  Edit,
  RemoveRedEye,
  PictureAsPdf,
} from "@mui/icons-material";
import StyledTableCell from "./StyledTableCell";
import StyledTableRow from "./StyledTableRow";
import { CustomTablePagination } from "./customTablePagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CommonModal from "./commonModal";
import CommonExtModal from "./commonExtModal";
import {
  exportToPDFOpenCredit,
  getReasonCombo,
  saveOrUpdateCreditExtension,
  exportToPDFOpenDebit,
} from "../api/service";
import { isEmpty } from "lodash";
import { formatMoney } from "../../constants/utils";

const CommonCardDesign = (props) => {
  const history = useNavigate();
  const [reasonData, setReasonData] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openExtModal, setOpenExtModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalData, setModalData] = useState({});
  const [saveModalData, setSaveModalData] = useState({});
  const isDistributorUser =
    JSON.parse(sessionStorage.getItem("userData")).groupId === 0;

  const onOpenViewModal = (data = {}) => {
    setModalData(data);
    setOpenViewModal(true);
  };

  const onOpenExtModal = (data = {}) => {
    console.log(selectedvalues, "selectedvalue");
    setSaveModalData(data);
    setOpenExtModal(true);
  };

  const {
    tableData,
    tableHeaders,
    tableColumns,
    onOpenModal,
    editId,
    editMode,
    rowsPerPageOptions,
    count,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    sx,
    tableModal,
    dueDate,
    displayPagination = true,
    showPdf = false,
    from = null,
    maxHeight = 440,
    isAdjustmentDetails = false,
    isAdminUser = false,
    showDetailedView = false,
    hideActionButton = false,
  } = props;

  const getColumnValue = (row, column) => {
    if (column === "status") {
      return row[column] === "Y" ? "Active" : "In-Active";
    } else if (column === "createdDate" || column === "updatedDate") {
      return moment(row[column]).format("DD-MM-YYYY");
    } else if (column === "division") {
      if (row[column] === "10") {
        return "Dixcy"; // Core
      } else if (row[column] === "20") {
        return "Maximus"; // Max
      } else if (row[column] === "30") {
        return "Levis";
      } else if (row[column] === "40") {
        return "Enamor";
      } else if (row[column] === "50") {
        return "Thermal";
      }
    } else if (
      column === "balanceExcess" ||
      column === "cdCredit" ||
      column === "cdNotEligible" ||
      column === "totalInvoiceAmt" ||
      column === "toBeReceived" ||
      column === "receiptCn" ||
      column === "balanceAmount" ||
      column === "cdamount" ||
      column === "sdBillAmt" ||
      column === "balanceDebit" ||
      column === "balanceCnAmt" ||
      column === "invoiceAmount" ||
      column === "totalInvoiceAmtNew" ||
      column === "receiptCnNew" ||
      column === "cdReceivedAmtNew" ||
      column === "balanceExcessNew" ||
      column === "secondCDAmount" ||
      column === "thirdCDAmount" ||
      column === "fourthCDAmount" ||
      column === "debitAmount" ||
      column === "creditAmount"
    ) {
      return formatMoney(row[column]?.toFixed(0));
    } else if (column === "revisedBalanceExcess") {
      return row.balanceExcess
        ? formatMoney((row?.balanceExcess + row?.cdCredit).toFixed(0))
        : "";
    }
    return row[column];
  };

  const exportPdfData = (row) => {
    if (from === "Open Credit" || from === "Open Credit History") {
      setLoading(true);
      exportToPDFOpenCredit(
        row.customerCode,
        row,
        moment(new Date()).format("DD_MM_YYYY_HH_MM_SS")
      ).then(() => {
        setLoading(false);
      });
    }

    if (from === "Open Debit" || from === "Open Debit History") {
      setLoading(true);
      exportToPDFOpenDebit(
        row.customerCode,
        row,
        moment(new Date()).format("DD_MM_YYYY_HH_MM_SS")
      ).then(() => {
        setLoading(false);
      });
    }
  };

  const redirectPath = (path, division) => {
    history(`${process.env.PUBLIC_URL}/reports${path}`, {
      state: {
        divisionParam: division,
      },
    });
  };

  useEffect(() => {
    loadReasonCombo();
  }, []);

  const onHandleSave = () => {
    setLoading(true);
    saveModalData.detailList = selectedvalues;
    saveModalData.customerCode = selectedvalues[0].customerCode;
    saveOrUpdateCreditExtension(saveModalData).then((res) => {
      toast.success("Successfully Created !", { autoClose: 1000 });

      setLoading(false);
      setOpenExtModal(false);
      setSaveModalData({
        cdextensionDays: 0,
        reason: "",
        otherReason: "",
        remarks: "",
      });
      setSelectedvalues([]);
    });
  };

  const loadReasonCombo = () => {
    getReasonCombo(page, 100).then((res) => {
      setReasonData(res.response.responseObject);
    });
  };
  const [selectedvalues, setSelectedvalues] = useState([]);

  const selectInvoiceNumber = (e, i) => {
    if (!e.target.checked) {
      setSelectedvalues(selectedvalues.filter((item, j) => i !== item));
    } else {
      setSelectedvalues([i, ...selectedvalues]);
    }
  };

  return (
    <Fragment>
      <Card>
        {dueDate == true && !isDistributorUser && !isEmpty(selectedvalues) ? (
          <DateRange
            sx={{
              fontSize: 20,
              margin: 0.4,
              color: "#EF4123",
            }}
            onClick={() => onOpenExtModal()}
          />
        ) : null}

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: maxHeight }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="-striped -highlight"
            >
              <TableHead>
                {isAdjustmentDetails && (
                  <TableRow
                    sx={{
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <StyledTableCell hidden />

                    <StyledTableCell
                      style={{
                        border: "1px solid #adadad",
                        backgroundColor: "#fbfdc7",
                      }}
                      align="center"
                      colSpan={showDetailedView ? 13 : 6}
                    >
                      Invoice, LR Date & Due date details
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        border: "1px solid #adadad",
                        backgroundColor: "#fbfdc7",
                      }}
                      align="center"
                      colSpan={showDetailedView ? 5 : 4}
                    >
                      Adjustment Details
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        border: "1px solid #adadad",
                        backgroundColor: "#fbfdc7",
                      }}
                      align="center"
                      colSpan={showDetailedView ? 6 : 5}
                    >
                      Payment Term & CD Eligibility
                    </StyledTableCell>
                    {showDetailedView && (
                      <StyledTableCell
                        style={{
                          border: "1px solid #adadad",
                          backgroundColor: "#fbfdc7",
                        }}
                        align="center"
                        colSpan={10}
                      >
                        Payment Term & Slab Details
                      </StyledTableCell>
                    )}
                  </TableRow>
                )}
                <TableRow
                  sx={{
                    position: "sticky",
                    top: isAdjustmentDetails ? 42 : 0,
                  }}
                >
                  <StyledTableCell hidden />
                  {!hideActionButton ? (
                    editMode === true ? (
                      <StyledTableCell align="center"> Action</StyledTableCell>
                    ) : (
                      <StyledTableCell align="center">Action</StyledTableCell>
                    )
                  ) : null}
                  {dueDate == true && !isDistributorUser ? (
                    <StyledTableCell align="center">Due Date</StyledTableCell>
                  ) : null}
                  {tableHeaders.map((row, index) => {
                    return (
                      <StyledTableCell
                        align="center"
                        style={{
                          minWidth: isAdjustmentDetails ? 120 : 170,
                        }}
                      >
                        {row}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <StyledTableRow hover role="checkbox">
                        <StyledTableCell hidden></StyledTableCell>
                        {!hideActionButton ? (
                          editMode === true ? (
                            <StyledTableCell align="center">
                              <Edit
                                onClick={() => onOpenModal(row)}
                                sx={{
                                  fontSize: 20,
                                  margin: 0.4,
                                  color: "#27a746",
                                }}
                              ></Edit>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell align="center">
                              <RemoveRedEye
                                sx={{
                                  fontSize: 20,
                                  margin: 0.4,
                                  color: "#EF4123",
                                  cursor: "pointer",
                                }}
                                onClick={() => onOpenViewModal(row)}
                              />
                              {showPdf &&
                                ((row.documentNumber &&
                                  row.documentType !== "DP" &&
                                  row.documentType !== "DZ") ||
                                  row.sdBillingDocNo) && (
                                  <PictureAsPdf
                                    onClick={() => exportPdfData(row)}
                                    sx={{
                                      fontSize: 20,
                                      margin: 0.4,
                                      color: "#EF4123",
                                      cursor: "pointer",
                                    }}
                                  ></PictureAsPdf>
                                )}
                            </StyledTableCell>
                          )
                        ) : null}
                        {dueDate == true && !isDistributorUser ? (
                          <StyledTableCell align="center">
                            <div>
                              <span>
                                <input
                                  type="checkbox"
                                  name={row.documentNumber}
                                  defaultChecked={selectedvalues.includes(row)}
                                  onChange={(e) => selectInvoiceNumber(e, row)}
                                />
                              </span>
                            </div>
                          </StyledTableCell>
                        ) : null}
                        {/* {dueDate == true ? (
                          <StyledTableCell align="center">
                            <DateRange
                              sx={{
                                fontSize: 20,
                                margin: 0.4,
                                color: "#EF4123",
                              }}
                              onClick={() => onOpenExtModal(row)}
                            />
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell align="center">

                          </StyledTableCell>
                        )} */}

                        {tableColumns.map((column, index) => {
                          return (
                            <StyledTableCell
                              align="center"
                              style={{
                                color:
                                  column === "status"
                                    ? row[column] === "Y"
                                      ? "green"
                                      : "red"
                                    : "",
                                padding: 8,
                              }}
                              onClick={() => {
                                if (
                                  column === "balanceAmount" &&
                                  row["redirectPath"] != null
                                ) {
                                  redirectPath(
                                    row["redirectPath"],
                                    row["redirectDivision"]
                                  );
                                }
                              }}
                            >
                              {getColumnValue(row, column)}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {displayPagination && (
            <CustomTablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              sx={{ float: "right" }}
            />
          )}
        </Paper>
      </Card>
      {/* <Card padding={3} spacing={2}>
        {tableData.map((item, index) => {
          return (

            <Paper square={false} elevation={3} style={{ margin: 5 }}>
              <Card style={{ maxHeight: 50, alignContent: 'center' }}>
                <CardBody className={classes.cardbody}>
                  <Grid container spacing={4}>
                    {tableHeaders.map((row, index) => {
                      let columnName = row.column;
                      return (
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: 12 }} color={"black"} fontWeight={700}>{row.name}</Typography>
                          <Typography style={{ fontSize: 10 }}>{item[columnName]}</Typography>
                        </Grid>
                      );
                    })}
                    <Grid item xs={2}>
                      <RemoveRedEye />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Paper>

          )
        })}
      </Card> */}
      <CommonModal
        open={openViewModal}
        modalData={modalData}
        onCancel={() => {
          setOpenViewModal(false);
          setModalData({});
        }}
        tableModalHeaders={tableModal}
      />

      <CommonExtModal
        open={openExtModal}
        saveModalData={saveModalData}
        onCancel={() => {
          setOpenExtModal(false);
          setSaveModalData({
            cdextensionDays: 0,
            reason: "",
            otherReason: "",
            remarks: "",
          });
        }}
        tableModalHeaders={tableModal}
        setSaveModalData={setSaveModalData}
        reasonData={reasonData}
        onSave={onHandleSave}
      />

      <ToastContainer />
    </Fragment>
  );
};
export default CommonCardDesign;
