import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "./common/breadcrumb";
import {
  Navigation,
  Box,
  MessageSquare,
  Users,
  Briefcase,
  CreditCard,
  ShoppingCart,
  Calendar,
  Home,
  User,
} from "react-feather";
import CountUp from "react-countup";
import { Chart } from "react-google-charts";

import { Bar, Line } from "react-chartjs-2";
import {
  lineOptions,
  buyOption,
  employeeData,
  employeeOptions,
} from "../constants/chartData";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  ArcElement,
  Filler,
  RadialLinearScale,
} from "chart.js";

// image impoer
import user2 from "../assets/images/dashboard/user2.jpg";
import user1 from "../assets/images/dashboard/user1.jpg";
import man from "../assets/images/dashboard/man.png";
import user from "../assets/images/dashboard/user.png";
import designer from "../assets/images/dashboard/designer.jpg";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  DocumentScanner,
  FileCopySharp,
  CreditCardOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  getAllPolicyMaster,
  getDistributorInfoByCustomerCode,
} from "./api/service";
import { useCustomerCode } from "./context/CustomerCodeProvider";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  ArcElement,
  Filler,
  RadialLinearScale
);

const useStyles = makeStyles((theme) => ({
  dashMainCol: {
    display: "flex",
    justifyContent: "center",
  },

  dashMainMedia: {
    display: "flex",
    alignItems: "center !important",
  },

  dashMainDiv: {
    display: "grid",
    placeItems: "center",
  },

  dashIconDiv: {
    background: "aliceblue !important",
    marginBottom: "10px",
    width: "70px !important",
    height: "70px !important",
  },

  dashIcon: {
    width: "30px !important",
    height: "30px !important",
    color: "rgb(239, 65, 35)",
  },

  textBoldTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    width: "220px !important",
  },
  textBold: {
    fontWeight: "bold",
    fontSize: "18px",
  },
}));

const Dashboard = () => {
  const lineData = {
    labels: ["100", "200", "300", "400", "500", "600", "700", "800"],
    datasets: [
      {
        data: [2.5, 3, 3, 0.9, 1.3, 1.8, 3.8, 1.5],
        borderColor: "#ff8084",
        backgroundColor: "#ff8084",
        borderWidth: 2,
        barPercentage: 0.7,
        categoryPercentage: 0.4,
      },
      {
        data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
        borderColor: "#a5a5a5",
        backgroundColor: "#a5a5a5",
        borderWidth: 2,
        barPercentage: 0.7,
        categoryPercentage: 0.4,
      },
    ],
  };

  const buyData = {
    labels: ["", "10", "20", "30", "40", "50"],
    datasets: [
      {
        backgroundColor: "transparent",
        borderColor: "#13c9ca",
        data: [20, 5, 80, 10, 100, 15],
        lineTension: 0.4,
      },
      {
        backgroundColor: "transparent",
        borderColor: "#a5a5a5",
        data: [0, 50, 20, 70, 30, 27],
        lineTension: 0.4,
      },
      {
        backgroundColor: "transparent",
        borderColor: "#ff8084",
        data: [0, 30, 40, 10, 86, 40],
        lineTension: 0.4,
      },
    ],
  };

  const doughnutOptions = {
    title: "",
    pieHole: 0.35,
    pieSliceBorderColor: "none",
    colors: ["#ff8084", "#13c9ca", "#a5a5a5"],
    legend: {
      position: "none",
    },
    pieSliceText: "none",
    tooltip: {
      trigger: "none",
    },
    animation: {
      startup: true,
      easing: "linear",
      duration: 1500,
    },
    chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
    enableInteractivity: false,
  };
  const pieOptions = {
    title: "",
    pieHole: 1,
    slices: [
      {
        color: "#ff8084",
      },
      {
        color: "#13c9ca",
      },
      {
        color: "#f0b54d",
      },
    ],
    tooltip: {
      showColorCode: false,
    },
    chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
    legend: "none",
  };
  const LineOptions = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#ff8084"],
    legend: "none",
  };
  const LineOptions1 = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#13c9ca"],
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    legend: "none",
  };
  const LineOptions2 = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#f5ce8a"],
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    legend: "none",
  };
  const LineOptions3 = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#a5a5a5"],
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    legend: "none",
  };

  const history = useNavigate();
  const classes = useStyles();
  const isAsmUser =
    JSON.parse(sessionStorage.getItem("userData")).groupId === 5 || //ASM
    JSON.parse(sessionStorage.getItem("userData")).groupId === 3 || //BM
    JSON.parse(sessionStorage.getItem("userData")).groupId === 4 || //BSM
    JSON.parse(sessionStorage.getItem("userData")).groupId === 6 || //BCM
    JSON.parse(sessionStorage.getItem("userData")).groupId === 11; //AGENT

  const isFH_CEO_CFO_CSO_User =
    JSON.parse(sessionStorage.getItem("userData")).groupId === 7 || // FH
    JSON.parse(sessionStorage.getItem("userData")).groupId === 8 || //CEO
    JSON.parse(sessionStorage.getItem("userData")).groupId === 9 || // CFO
    JSON.parse(sessionStorage.getItem("userData")).groupId === 10; //CSO
  const [poicyData, setPolicyData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalContent, setModalContent] = useState(
    "<h1>No Content Found </h1>"
  );
  const [distributorData, setDistributorData] = useState(null);

  const { selectedCustomerCode } = useCustomerCode();

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onCloseBankDetailsModal = () => {
    setShowBankDetailsModal(false);
  };

  const onOpenModal = (row) => {
    setModalContent(row.description);
    setModalHeading(row.policyName);
    setShowModal(true);
  };

  const isDistributorUser =
    JSON.parse(sessionStorage.getItem("userData")).groupId === 0;

  useEffect(() => {
    loadListData();
  }, [selectedCustomerCode]);

  const loadListData = () => {
    // setLoading(true);
    console.log(selectedCustomerCode);
    getAllPolicyMaster(0, 100, selectedCustomerCode).then((res) => {
      // setLoading(false);
      setPolicyData(res.response.responseObject);
    });

    getDistributorInfoByCustomerCode(selectedCustomerCode).then((res) => {
      setDistributorData(res.response.responseObject[0]);
    });
  };

  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          {!isDistributorUser && !isAsmUser && !isFH_CEO_CFO_CSO_User && (
            <>
              <Breadcrumb title="Master" parent="" />
              <Card className=" o-hidden widget-cards">
                <CardBody style={{ display: "flex", overflow: "scroll" }}>
                  {!isAsmUser && !isFH_CEO_CFO_CSO_User && (
                    <>
                      <Col
                        onClick={() =>
                          history(
                            `${process.env.PUBLIC_URL}/master/regionMaster`
                          )
                        }
                        xl="2"
                        md="2"
                        sm="2"
                        className={classes.dashMainCol}
                      >
                        <Media
                          className={`static-top-widget ${classes.dashMainMedia}`}
                        >
                          <div
                            className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                          >
                            <div
                              className={`align-self-center text-center ${classes.dashIconDiv}`}
                            >
                              <Home className={classes.dashIcon} />
                            </div>

                            <h4 className="mb-0">
                              <small>Region Master</small>
                            </h4>
                          </div>
                        </Media>
                      </Col>
                      <Col
                        onClick={() =>
                          history(
                            `${process.env.PUBLIC_URL}/master/stateMaster`
                          )
                        }
                        xl="2"
                        md="2"
                        sm="2"
                        className={classes.dashMainCol}
                      >
                        <Media
                          className={`static-top-widget ${classes.dashMainMedia}`}
                        >
                          <div
                            className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                          >
                            <div
                              className={`align-self-center text-center ${classes.dashIconDiv}`}
                            >
                              <FileCopySharp className={classes.dashIcon} />
                            </div>

                            <h4 className="mb-0 ">
                              <small>State Master</small>
                            </h4>
                          </div>
                        </Media>
                      </Col>
                      <Col
                        onClick={() =>
                          history(
                            `${process.env.PUBLIC_URL}/master/divisionMaster`
                          )
                        }
                        xl="2"
                        md="2"
                        sm="2"
                        className={classes.dashMainCol}
                      >
                        <Media
                          className={`static-top-widget ${classes.dashMainMedia}`}
                        >
                          <div
                            className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                          >
                            <div
                              className={`align-self-center text-center ${classes.dashIconDiv}`}
                            >
                              <FileCopySharp className={classes.dashIcon} />
                            </div>

                            <h4 className="mb-0">
                              <small>Division Master</small>
                            </h4>
                          </div>
                        </Media>
                      </Col>
                    </>
                  )}
                  <Col
                    onClick={() =>
                      history(
                        `${process.env.PUBLIC_URL}/master/distributorMaster`
                      )
                    }
                    xl="2"
                    md="2"
                    sm="2"
                    className={classes.dashMainCol}
                  >
                    <Media
                      className={`static-top-widget ${classes.dashMainMedia}`}
                    >
                      <div
                        className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                      >
                        <div
                          className={`align-self-center text-center ${classes.dashIconDiv}`}
                        >
                          <DocumentScanner className={classes.dashIcon} />
                        </div>

                        <h4 className="mb-0">
                          <small>Distributor Master</small>
                        </h4>
                      </div>
                    </Media>
                  </Col>
                  <Col
                    onClick={() =>
                      history(
                        `${process.env.PUBLIC_URL}/master/distributorMapping`
                      )
                    }
                    xl="2"
                    md="2"
                    sm="2"
                    className={classes.dashMainCol}
                  >
                    <Media
                      className={`static-top-widget ${classes.dashMainMedia}`}
                    >
                      <div
                        className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                      >
                        <div
                          className={`align-self-center text-center ${classes.dashIconDiv}`}
                        >
                          <DocumentScanner className={classes.dashIcon} />
                        </div>

                        <h4 className="mb-0">
                          <small>Distributor Mapping</small>
                        </h4>
                      </div>
                    </Media>
                  </Col>
                  {!isAsmUser && !isFH_CEO_CFO_CSO_User && (
                    <>
                      <Col
                        onClick={() =>
                          history(
                            `${process.env.PUBLIC_URL}/master/policyMaster`
                          )
                        }
                        xl="2"
                        md="2"
                        sm="2"
                        className={classes.dashMainCol}
                      >
                        <Media
                          className={`static-top-widget ${classes.dashMainMedia}`}
                        >
                          <div
                            className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                          >
                            <div
                              className={`align-self-center text-center ${classes.dashIconDiv}`}
                            >
                              <DocumentScanner className={classes.dashIcon} />
                            </div>

                            <h4 className="mb-0">
                              <small>Policy Master</small>
                            </h4>
                          </div>
                        </Media>
                      </Col>
                      <Col
                        onClick={() =>
                          history(
                            `${process.env.PUBLIC_URL}/master/reasonMaster`
                          )
                        }
                        xl="2"
                        md="2"
                        sm="2"
                        className={classes.dashMainCol}
                      >
                        <Media
                          className={`static-top-widget ${classes.dashMainMedia}`}
                        >
                          <div
                            className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                          >
                            <div
                              className={`align-self-center text-center ${classes.dashIconDiv}`}
                            >
                              <DocumentScanner className={classes.dashIcon} />
                            </div>

                            <h4 className="mb-0">
                              <small>Reason</small>
                            </h4>
                          </div>
                        </Media>
                      </Col>
                    </>
                  )}
                </CardBody>
              </Card>
            </>
          )}

          <Breadcrumb title="Account Statement" parent="" />
          <Card className=" o-hidden widget-cards">
            <CardBody style={{ display: "flex" }}>
              <Col
                onClick={() =>
                  history(`${process.env.PUBLIC_URL}/reports/outstandingBill`)
                }
                xl="2"
                md="2"
                sm="2"
                className={classes.dashMainCol}
              >
                <Media className={`static-top-widget ${classes.dashMainMedia}`}>
                  <div
                    className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                  >
                    <div
                      className={`align-self-center text-center ${classes.dashIconDiv}`}
                    >
                      <Home className={classes.dashIcon} />
                    </div>

                    <h4 className="mb-0">
                      <small>Outstanding Bill</small>
                    </h4>
                  </div>
                </Media>
              </Col>
              <Col
                onClick={() =>
                  history(`${process.env.PUBLIC_URL}/reports/openDebit`)
                }
                xl="2"
                md="2"
                sm="2"
                className={classes.dashMainCol}
              >
                <Media className={`static-top-widget ${classes.dashMainMedia}`}>
                  <div
                    className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                  >
                    <div
                      className={`align-self-center text-center ${classes.dashIconDiv}`}
                    >
                      <FileCopySharp className={classes.dashIcon} />
                    </div>

                    <h4 className="mb-0 ">
                      <small>Open Debit</small>
                    </h4>
                  </div>
                </Media>
              </Col>
              <Col
                onClick={() =>
                  history(`${process.env.PUBLIC_URL}/reports/openCredit`)
                }
                xl="2"
                md="2"
                sm="2"
                className={classes.dashMainCol}
              >
                <Media className={`static-top-widget ${classes.dashMainMedia}`}>
                  <div
                    className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                  >
                    <div
                      className={`align-self-center text-center ${classes.dashIconDiv}`}
                    >
                      <FileCopySharp className={classes.dashIcon} />
                    </div>

                    <h4 className="mb-0">
                      <small>Open Credit</small>
                    </h4>
                  </div>
                </Media>
              </Col>
              <Col
                onClick={() =>
                  history(`${process.env.PUBLIC_URL}/reports/adjustmentReport`)
                }
                xl="2"
                md="2"
                sm="2"
                className={classes.dashMainCol}
              >
                <Media className={`static-top-widget ${classes.dashMainMedia}`}>
                  <div
                    className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                  >
                    <div
                      className={`align-self-center text-center ${classes.dashIconDiv}`}
                    >
                      <DocumentScanner className={classes.dashIcon} />
                    </div>

                    <h4 className="mb-0">
                      <small>Adjustment Report</small>
                    </h4>
                  </div>
                </Media>
              </Col>
              {!isDistributorUser && (
                <Col
                  onClick={() =>
                    history(`${process.env.PUBLIC_URL}/reports/ledger`)
                  }
                  xl="2"
                  md="2"
                  sm="2"
                  className={classes.dashMainCol}
                >
                  <Media
                    className={`static-top-widget ${classes.dashMainMedia}`}
                  >
                    <div
                      className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                    >
                      <div
                        className={`align-self-center text-center ${classes.dashIconDiv}`}
                      >
                        <FileCopySharp className={classes.dashIcon} />
                      </div>

                      <h4 className="mb-0 ">
                        <small>Ledger</small>
                      </h4>
                    </div>
                  </Media>
                </Col>
              )}
              <Col
                onClick={() => setShowBankDetailsModal(true)}
                xl="2"
                md="2"
                sm="2"
                className={classes.dashMainCol}
              >
                <Media className={`static-top-widget ${classes.dashMainMedia}`}>
                  <div
                    className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                  >
                    <div
                      className={`align-self-center text-center ${classes.dashIconDiv}`}
                    >
                      <CreditCardOutlined className={classes.dashIcon} />
                    </div>

                    <h4 className="mb-0">
                      <small>Bank Account Details</small>
                    </h4>
                  </div>
                </Media>
              </Col>
            </CardBody>
          </Card>

          <Breadcrumb title="Confirmation / Declarations" parent="" />
          <Card className=" o-hidden widget-cards">
            <CardBody style={{ display: "flex" }}>
              {!isAsmUser && !isFH_CEO_CFO_CSO_User && !isDistributorUser && (
                <Col
                  onClick={() =>
                    history(`${process.env.PUBLIC_URL}/master/creditExtension`)
                  }
                  xl="2"
                  md="2"
                  sm="2"
                  className={classes.dashMainCol}
                >
                  <Media
                    className={`static-top-widget ${classes.dashMainMedia}`}
                  >
                    <div
                      className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                    >
                      <div
                        className={`align-self-center text-center ${classes.dashIconDiv}`}
                      >
                        <Home className={classes.dashIcon} />
                      </div>

                      <h4 className="mb-0">
                        <small>Credit Extension</small>
                      </h4>
                    </div>
                  </Media>
                </Col>
              )}
              <Col
                onClick={() =>
                  history(
                    `${process.env.PUBLIC_URL}/master/balanceConfirmation`
                  )
                }
                xl="2"
                md="2"
                sm="2"
                className={classes.dashMainCol}
              >
                <Media className={`static-top-widget ${classes.dashMainMedia}`}>
                  <div
                    className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                  >
                    <div
                      className={`align-self-center text-center ${classes.dashIconDiv}`}
                    >
                      <FileCopySharp className={classes.dashIcon} />
                    </div>

                    <h4 className="mb-0 ">
                      <small>Balance Confirmation</small>
                    </h4>
                  </div>
                </Media>
              </Col>
              <Col
                onClick={() =>
                  history(`${process.env.PUBLIC_URL}/master/tds194RDeclaration`)
                }
                xl="2"
                md="2"
                sm="2"
                className={classes.dashMainCol}
              >
                <Media className={`static-top-widget ${classes.dashMainMedia}`}>
                  <div
                    className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                  >
                    <div
                      className={`align-self-center text-center ${classes.dashIconDiv}`}
                    >
                      <FileCopySharp className={classes.dashIcon} />
                    </div>

                    <h4 className="mb-0 ">
                      <small>194R TDS Declaration</small>
                    </h4>
                  </div>
                </Media>
              </Col>
              {!isAsmUser && !isFH_CEO_CFO_CSO_User && !isDistributorUser && (
                <Col
                  onClick={() =>
                    history(`${process.env.PUBLIC_URL}/master/ndc`)
                  }
                  xl="2"
                  md="2"
                  sm="2"
                  className={classes.dashMainCol}
                >
                  <Media
                    className={`static-top-widget ${classes.dashMainMedia}`}
                  >
                    <div
                      className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                    >
                      <div
                        className={`align-self-center text-center ${classes.dashIconDiv}`}
                      >
                        <FileCopySharp className={classes.dashIcon} />
                      </div>

                      <h4 className="mb-0">
                        <small>NDC</small>
                      </h4>
                    </div>
                  </Media>
                </Col>
              )}
            </CardBody>
          </Card>

          {!isAsmUser && !isFH_CEO_CFO_CSO_User && !isDistributorUser && (
            <>
              <Breadcrumb title="User Management" parent="" />
              <Card className=" o-hidden widget-cards">
                <CardBody style={{ display: "flex" }}>
                  <Col
                    onClick={() =>
                      history(`${process.env.PUBLIC_URL}/userManagement/users`)
                    }
                    xl="2"
                    md="2"
                    sm="2"
                    className={classes.dashMainCol}
                  >
                    <Media
                      className={`static-top-widget ${classes.dashMainMedia}`}
                    >
                      <div
                        className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                      >
                        <div
                          className={`align-self-center text-center ${classes.dashIconDiv}`}
                        >
                          <User className={classes.dashIcon} />
                        </div>

                        <h4 className="mb-0">
                          <small>User</small>
                        </h4>
                      </div>
                    </Media>
                  </Col>
                  <Col
                    onClick={() =>
                      history(`${process.env.PUBLIC_URL}/userManagement/group`)
                    }
                    xl="2"
                    md="2"
                    sm="2"
                    className={classes.dashMainCol}
                  >
                    <Media
                      className={`static-top-widget ${classes.dashMainMedia}`}
                    >
                      <div
                        className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                      >
                        <div
                          className={`align-self-center text-center ${classes.dashIconDiv}`}
                        >
                          <Users className={classes.dashIcon} />
                        </div>

                        <h4 className="mb-0 ">
                          <small>Group</small>
                        </h4>
                      </div>
                    </Media>
                  </Col>
                  <Col
                    onClick={() =>
                      history(
                        `${process.env.PUBLIC_URL}/userManagement/userLog`
                      )
                    }
                    xl="2"
                    md="2"
                    sm="2"
                    className={classes.dashMainCol}
                  >
                    <Media
                      className={`static-top-widget ${classes.dashMainMedia}`}
                    >
                      <div
                        className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                      >
                        <div
                          className={`align-self-center text-center ${classes.dashIconDiv}`}
                        >
                          <User className={classes.dashIcon} />
                        </div>

                        <h4 className="mb-0">
                          <small>User Logs</small>
                        </h4>
                      </div>
                    </Media>
                  </Col>
                </CardBody>
              </Card>
            </>
          )}
          {true && (
            <>
              <Breadcrumb title="Policies" parent="" />
              <Card className=" o-hidden widget-cards">
                <CardBody style={{ display: "flex" }}>
                  {poicyData
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <Col
                          onClick={() => onOpenModal(row)}
                          xl="2"
                          md="2"
                          sm="2"
                          className={classes.dashMainCol}
                        >
                          <Media
                            className={`static-top-widget ${classes.dashMainMedia}`}
                          >
                            <div
                              className={`icons-widgets col-12 text-center ${classes.dashMainDiv}`}
                            >
                              <div
                                className={`align-self-center text-center ${classes.dashIconDiv}`}
                              >
                                <FileCopySharp className={classes.dashIcon} />
                              </div>

                              <h4 className="mb-0">
                                <small>{row.policyName}</small>
                              </h4>
                            </div>
                          </Media>
                        </Col>
                      );
                    })}
                </CardBody>
              </Card>
            </>
          )}
        </Row>
      </Container>
      <Modal
        isOpen={showModal}
        size="xl"
        toggle={onCloseModal}
        style={{ overlay: { opacity: 0.1 } }}
      >
        <ModalHeader toggle={onCloseModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            {modalHeading}
          </h5>
        </ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={createMarkup(modalContent)} />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>

      <Modal
        isOpen={showBankDetailsModal}
        size="xl"
        toggle={onCloseBankDetailsModal}
        style={{ overlay: { opacity: 0.1 } }}
      >
        <ModalHeader toggle={onCloseBankDetailsModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            {"Bank Account Details"}
          </h5>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12} xs={12}>
              <h5 style={{ color: "#EF4123" }}>
                {`Note: For payments made to a particular category/brand stated below, please use the right account number as mentioned respectively.`}
              </h5>
            </Col>
            <Col lg={6} xs={12}>
              <div className="tab-pane fade show active">
                <h5 className="f-w-600 f-16">Account Details</h5>
                <div className="table-responsive profile-table">
                  <Table
                    className="table-responsive"
                    style={{ border: "8px solid #e4db1e" }}
                  >
                    <tbody>
                      {distributorData?.dixcyVirtualAc && (
                        <tr>
                          <td className={classes.textBoldTitle}>
                            Dixcy Account No:
                          </td>
                          <td className={classes.textBold}>
                            {distributorData?.dixcyVirtualAc}
                          </td>
                        </tr>
                      )}
                      {distributorData?.enamorVirtualAc && (
                        <tr>
                          <td className={classes.textBoldTitle}>
                            Enamor Account No:
                          </td>
                          <td className={classes.textBold}>
                            {distributorData?.enamorVirtualAc}
                          </td>
                        </tr>
                      )}
                      {distributorData?.maximusVirtualAc && (
                        <tr>
                          <td className={classes.textBoldTitle}>
                            Maximus Account No:
                          </td>
                          <td className={classes.textBold}>
                            {distributorData?.maximusVirtualAc}
                          </td>
                        </tr>
                      )}
                      {distributorData?.levisVirtualAc && (
                        <tr>
                          <td className={classes.textBoldTitle}>
                            Levis Account No:
                          </td>
                          <td className={classes.textBold}>
                            {distributorData?.levisVirtualAc}
                          </td>
                        </tr>
                      )}
                      {distributorData?.thermalVirtualAc && (
                        <tr>
                          <td className={classes.textBoldTitle}>
                            Thermal Account No:
                          </td>
                          <td className={classes.textBold}>
                            {distributorData.thermalVirtualAc}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <div className="tab-pane fade show active">
                <h5 className="f-w-600 f-16">Bank Details</h5>
                <div className="table-responsive profile-table">
                  <Table className="table-responsive">
                    <tbody>
                      <tr>
                        <td>Beneficiary Name:</td>
                        <td>{distributorData?.beneficiaryName}</td>
                      </tr>
                      <tr>
                        <td>Bank Name:</td>
                        <td>{distributorData?.bankName}</td>
                      </tr>
                      <tr>
                        <td>IFSC:</td>
                        <td>{distributorData?.ifscCode}</td>
                      </tr>
                      <tr>
                        <td>Client Code:</td>
                        <td>{distributorData?.clientCode}</td>
                      </tr>
                      <tr>
                        <td>Bank Branch:</td>
                        <td>{distributorData?.bankBranch}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>

            <Col lg={12} xs={12}>
              <h5 style={{ color: "#EF4123" }}>
                {`If the wrong account number is used, it shall result in incorrect and delay in payment adjustment.`}
              </h5>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </Fragment>
  );
};

// javascript:void(0)

export default Dashboard;
