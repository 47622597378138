import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardBody, Container, Row } from "reactstrap";
import { Button } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  PersonSearch,
  Refresh,
  SimCardDownloadRounded,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import BalanceConfirmationReportModal from "./balanceConfirmationReportModal";
import CommonAccordion from "../../common/commonAccordion";
import CommonTable from "../../common/commonTable";
import {
  balanceConfirmationReportTableData,
  balanceConfirmationReportTableHeader,
} from "../../common/commonConstant";
import { useEffect } from "react";
import {
  exportToExcelBalanceConfirmationConsolidate,
  // exportToExcelBalanceConfirmationReport,
  getAllBalanceConfirmationReport,
  getAllDistributorMasters,
  getBalanceConfirmationConsolidateReportByRegion,
  // searchBalanceConfirmationReportData,
} from "../../api/service";
import CommonLoader from "../../common/commonLoader";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const status = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Reject",
    label: "Reject",
  },
];

const BalanceConfirmationReport = () => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [balanceConfirmationReportData, setBalanceConfirmationReportData] =
    useState();
  const [rowData, setRowData] = useState({
    regionName: "",
    totalCount: "",
    totalClosingBalance: "",
    totalCustomerBalance: "",
    approvedCount: "",
    percentage: "",
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState([]);

  const filterInitialValues = {
    searchFinancialYear: "",
  };
  const [searchData, setSearchData] = useState(filterInitialValues);

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {
    setLoading(true);
    getAllBalanceConfirmationReport(page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
    });

    getAllDistributorMasters(page, rowsPerPage).then((res) => {
      setSearch(res.response.responseObject);
    });
  };
  const [open, setOpen] = useState(false);

  const onOpenModal = (row) => {
    if (row.regionMasterId || row.regionName === "All") {
      setLoading(true);

      getBalanceConfirmationConsolidateReportByRegion(
        row.regionMasterId ? row.regionMasterId : 0
      ).then((res) => {
        setLoading(false);
        setBalanceConfirmationReportData(res.response.responseObject);
      });
      setRowData(row);
      setOpen(true);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const filterData = () => {
    setLoading(true);
    // searchBalanceConfirmationReportData(searchData, page, rowsPerPage).then((res) => {
    //   setLoading(false);
    //   setData(res.responseMap.data);
    // });
  };

  const exportData = () => {
    setLoading(true);
    exportToExcelBalanceConfirmationConsolidate().then(() => {
      setLoading(false);
    });
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb title="Balance Confirmation Report" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <CommonAccordion accordionTitle={"Advanced Search"}>
            <Grid container columnSpacing={2} className={classes.muiGrid}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Financial Year"
                  size="small"
                  select
                  id="fullWidth"
                  name="searchFinancialYear"
                  value={searchData.searchFinancialYear}
                  onChange={onFilterInputChange}
                >
                  <MenuItem value="2022">FY-2022-23</MenuItem>
                  <MenuItem value="2023">FY-2023-24</MenuItem>
                  <MenuItem value="2024">FY-2024-25</MenuItem>
                </TextField>
              </Grid>
              {/* <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Status"
                  size="small"
                  name="searchStatus"
                  value={searchData.searchStatus}
                  onChange={onFilterInputChange}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}

              <Grid
                item
                sm={4}
                xs={12}
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => filterData()}
                >
                  <PersonSearch></PersonSearch>
                </Button>
                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                    setSearchData(filterInitialValues);
                    loadListData();
                  }}
                >
                  <Refresh></Refresh>
                </Button>
                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                    exportData();
                  }}
                >
                  <SimCardDownloadRounded></SimCardDownloadRounded>
                </Button>
              </Grid>
            </Grid>
          </CommonAccordion>
          <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  tableData={data}
                  tableHeaders={balanceConfirmationReportTableHeader}
                  tableColumns={balanceConfirmationReportTableData}
                  editId={"regionMasterId"}
                  editMode={true}
                  onOpenModal={onOpenModal}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                />
              </div>
            </CardBody>
          </Card>
        </Row>

        <BalanceConfirmationReportModal
          open={open}
          onCancel={() => {
            setOpen(false);
            setBalanceConfirmationReportData([]);
          }}
          balanceConfirmationReportData={balanceConfirmationReportData}
          rowData={rowData}
        />
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default BalanceConfirmationReport;
