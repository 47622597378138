import React from "react";
import man from "../../../assets/images/dashboard/usr1.png";

const UserPanel = () => {
  const loginUserData = JSON.parse(sessionStorage.getItem("userData"));
  return (
    <div>
      <div className="sidebar-user text-center">
        <div>
          <img
            className="img-60 rounded-circle lazyloaded blur-up"
            src={man}
            alt="#"
          />
        </div>
        <h6 className="mt-3 f-14">{loginUserData.name}</h6>
        {/* <p>gener	al manager.</p> */}
      </div>
    </div>
  );
};

export default UserPanel;
