import React, { Fragment } from "react";

import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { changePassword, updateLoginPin } from "../api/service";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { makeStyles } from "@mui/styles";
import { CircularProgress, Typography, useTheme } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  login: {
    marginTop: theme.spacing(1.5),
    fontWeight: 400,
    padding: theme.spacing(1.375, 5.625), //'11px 45px',
    background: "black!important",
    border: "none!important",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    marginTop: "3vh",
    borderRadius: theme.spacing(0.75),
    lineHeight: 1.8,
    "&:hover": {
      background: "black!important",
    },
    "&:focus": {
      background: "black!important",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: "10",
    transform: "translate(-50%, -50%)",
    width: "20%",
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const history = useNavigate();
  const theme = useTheme();

  const cpinitialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const [loading, setLoading] = React.useState(false);
  const [changePasswordValues, setChangePasswordValues] =
    React.useState(cpinitialValues);

  const handleInputChangePassword = (e) => {
    const value = e.target.value;
    setChangePasswordValues({
      ...changePasswordValues,
      [e.target.name]: value,
    });
  };

  const handleSubmitChangePassword = (event) => {
    event.preventDefault();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const isDistributorUser = userData.groupId === 0;
    const password = userData.password;
    if (
      changePasswordValues.oldPassword ===
      changePasswordValues.confirmNewPassword
    ) {
      alert("Old Password and New Password should not be same");
      return false;
    } else if (
      changePasswordValues.newPassword !==
      changePasswordValues.confirmNewPassword
    ) {
      alert("New Password and Confirm new Password are mismatched");
      return false;
    } else if (changePasswordValues.oldPassword !== password) {
      alert("Kindly enter Valid Old Password");
      return false;
    } else {
      setLoading(true);

      if (isDistributorUser) {
        updateLoginPin(
          JSON.parse(sessionStorage.getItem("userData")).userId,
          changePasswordValues.newPassword
        )
          .then((req) => {
            if (req.response.responseCode === true) {
              userData.password = changePasswordValues.newPassword;
              sessionStorage.setItem("userData", JSON.stringify(userData));
              setLoading(false);
              toast.success("Password Updated Successfully!", {
                autoClose: 2000,
              });
              history(`${process.env.PUBLIC_URL}/dashboard`);
            } else {
              setLoading(false);
              toast.warning("Something went wrong", {
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        changePassword(
          JSON.parse(sessionStorage.getItem("userData")).userId,
          changePasswordValues.newPassword
        )
          .then((req) => {
            if (req.response.responseCode === true) {
              userData.password = changePasswordValues.newPassword;
              sessionStorage.setItem("userData", JSON.stringify(userData));
              alert("Password Updated Successfully!");
              toast.success("Password Updated Successfully!", {
                autoClose: 2000,
              });
              setLoading(false);
              history(`${process.env.PUBLIC_URL}/dashboard`);
            } else {
              setLoading(false);
              toast.warning("Something went wrong", {
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Change Password" parent="Settings" />
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress disableShrink />
          <Typography
            component="span"
            sx={{ position: "absolute", top: theme.spacing(5.25) }}
          >
            <b>Loading...</b>
          </Typography>
        </div>
      ) : null}
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Form
                  className="form-horizontal auth-form"
                  method="post"
                  noValidate=""
                  onSubmit={handleSubmitChangePassword}
                >
                  <FormGroup>
                    <Input
                      required={true}
                      name="oldPassword"
                      id="oldPassword"
                      type="password"
                      value={changePasswordValues.oldPassword}
                      onChange={handleInputChangePassword}
                      className="form-control"
                      placeholder="Old Password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      required={true}
                      name="newPassword"
                      id="newPassword"
                      type="password"
                      value={changePasswordValues.newPassword}
                      onChange={handleInputChangePassword}
                      className="form-control"
                      placeholder="New Password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      name="confirmNewPassword"
                      id="confirmNewPassword"
                      type="password"
                      value={changePasswordValues.confirmNewPassword}
                      onChange={handleInputChangePassword}
                      className="form-control"
                      placeholder="Confirm New Password"
                      required={true}
                      minLength="4"
                    />
                  </FormGroup>

                  <div className="form-button">
                    <Button type="submit" className={classes.login}>
                      Change Password
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default ChangePassword;
