import React, { Fragment } from "react";
import { Card } from "reactstrap";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Edit, PictureAsPdf, FileDownload } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import StyledTableCell from "./StyledTableCell";
import StyledTableRow from "./StyledTableRow";
import { CustomTablePagination } from "./customTablePagination";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  exportToPDFBalanceConfirmation,
  exportToPDFTdsDeclaration,
} from "../api/service";
import CommonDialog from "./commonDialog";
import { formatMoney } from "../../constants/utils";

const useStyles = makeStyles((theme) => ({
  muiShadow: {
    boxShadow: "none!important",
  },
  muiArrow: {
    minHeight: "0!important",
    marginTop: `${theme.spacing(0)} !important`,
  },
}));

const CommonTable = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);

  const {
    tableData,
    tableHeaders,
    tableColumns,
    onOpenModal,
    editId,
    editMode,
    rowsPerPageOptions,
    count,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    sx,
    displayPagination = true,
    showPdf = false,
    dueDate,
    from = null,
    showFileDownload = false,
    showReset = false,
    isTDS194Declaration = false,
    openResetModal = () => {},
  } = props;

  const getColumnValue = (row, column) => {
    if (column === "status" && !isTDS194Declaration) {
      return row[column] === "Y" ? "Active" : "In-Active";
    } else if (column === "createdDate" || column === "updatedDate") {
      return row[column] ? moment(row[column]).format("DD-MM-YYYY") : "";
    } else if (column === "division") {
      if (row[column] === "10") {
        return "Dixcy"; // Core
      } else if (row[column] === "20") {
        return "Maximus"; // Max
      } else if (row[column] === "30") {
        return "Levis";
      } else if (row[column] === "40") {
        return "Enamor";
      } else if (row[column] === "50") {
        return "Thermal";
      }
    } else if (
      column === "customerBalanceAmount" ||
      column === "closingBalance" ||
      column === "totalClosingBalance" ||
      column === "totalCustomerBalance" ||
      column === "disagreedDifference"
    ) {
      return formatMoney(row[column]?.toFixed(0));
    } else if (column === "loginTime" || column === "loginTime") {
      return row[column]
        ? moment(row[column]).format("DD-MM-YYYY HH:mm A")
        : "";
    }
    return row[column];
  };

  const redirectPath = (path, division) => {
    history(`${process.env.PUBLIC_URL}/reports${path}`, {
      state: {
        divisionParam: division,
      },
    });
  };

  const onOpenViewModal = (data = {}) => {
    setModalData(data);
    setOpenViewModal(true);
  };

  const exportPdfData = (row) => {
    if (from === "Balance Confirmation") {
      setLoading(true);
      exportToPDFBalanceConfirmation(
        row.customerCode,
        row,
        moment(new Date()).format("DD_MM_YYYY_HH_MM_SS")
      ).then(() => {
        setLoading(false);
      });
    } else if (from === "194R TDS Declaration") {
      setLoading(true);
      exportToPDFTdsDeclaration(
        row.customerCode,
        row,
        moment(new Date()).format("DD_MM_YYYY_HH_MM_SS")
      ).then(() => {
        setLoading(false);
      });
    }
  };

  const viewBalanceConfirmationHistory = (data) => {
    history(`${process.env.PUBLIC_URL}/master/balanceConfirmationHistory`, {
      state: {
        balanceConfirmationIdParam: data.balanceConfirmationId,
      },
    });
  };

  return (
    <Fragment>
      <Card>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="-striped -highlight"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell hidden />
                  {editMode || showPdf ? (
                    <StyledTableCell align="center"> Action</StyledTableCell>
                  ) : null}

                  {showFileDownload ? (
                    <StyledTableCell align="center">
                      {" "}
                      View Ledger
                    </StyledTableCell>
                  ) : null}
                  {showReset && (
                    <>
                      <StyledTableCell align="center"> Reset</StyledTableCell>
                      <StyledTableCell align="center">History</StyledTableCell>
                    </>
                  )}
                  {tableHeaders.map((row, index) => {
                    return (
                      <StyledTableCell align="center" style={{ minWidth: 170 }}>
                        {row}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <StyledTableRow hover role="checkbox">
                        <StyledTableCell hidden></StyledTableCell>
                        {editMode === true || showPdf ? (
                          <StyledTableCell align="center">
                            {editMode === true &&
                              ((row.status === "Pending" &&
                                from === "194R TDS Declaration") ||
                                from !== "194R TDS Declaration") && (
                                <>
                                  <Edit
                                    onClick={() => onOpenModal(row)}
                                    sx={{
                                      fontSize: 20,
                                      margin: 0.4,
                                      color: "#27a746",
                                    }}
                                  ></Edit>
                                </>
                              )}
                            {showPdf && (
                              <>
                                {row.customerCode && (
                                  <PictureAsPdf
                                    onClick={() => exportPdfData(row)}
                                    sx={{
                                      fontSize: 20,
                                      margin: 0.4,
                                      color: "#EF4123",
                                      cursor: "pointer",
                                    }}
                                  ></PictureAsPdf>
                                )}
                              </>
                            )}
                          </StyledTableCell>
                        ) : null}

                        {showFileDownload === true && (
                          <StyledTableCell align="center">
                            {row.customerCode && (
                              <Link to={row.filePath} target="_blank">
                                <FileDownload
                                  //onClick={() => onOpenPopup(row)}
                                  sx={{
                                    fontSize: 20,
                                    margin: 0.4,
                                    color: "#EF4123",
                                    cursor: "pointer",
                                  }}
                                ></FileDownload>
                              </Link>
                            )}
                          </StyledTableCell>
                        )}

                        {showReset && (
                          <>
                            <StyledTableCell align="center">
                              {row?.balanceConfirmationStatus !== "Pending" && (
                                <Button
                                  color={"secondary"}
                                  variant="contained"
                                  type="button"
                                  size={"small"}
                                  onClick={() => openResetModal(row)}
                                >
                                  Reset
                                </Button>
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row?.revisionNo > 1 && (
                                <Button
                                  color={"secondary"}
                                  variant="outlined"
                                  type="button"
                                  size={"small"}
                                  onClick={() =>
                                    viewBalanceConfirmationHistory(row)
                                  }
                                >
                                  View
                                </Button>
                              )}
                            </StyledTableCell>
                          </>
                        )}
                        {tableColumns.map((column, index) => {
                          return (
                            <StyledTableCell
                              align="center"
                              style={{
                                color:
                                  column === "status"
                                    ? row[column] === "Y" ||
                                      row[column] === "Agreed"
                                      ? "green"
                                      : "red"
                                    : "",
                                padding: 8,
                              }}
                              onClick={() => {
                                if (
                                  column === "balanceAmount" &&
                                  row["redirectPath"] != null
                                ) {
                                  redirectPath(
                                    row["redirectPath"],
                                    row["redirectDivision"]
                                  );
                                }
                              }}
                            >
                              {getColumnValue(row, column)}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {displayPagination && (
            <CustomTablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              sx={{ float: "right" }}
            />
          )}
        </Paper>
      </Card>

      <ToastContainer />
    </Fragment>
  );
};
export default CommonTable;
