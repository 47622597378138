import { Grid } from "@mui/material";
import CommonDialog from "../../common/commonDialog";
import { Card, CardBody } from "reactstrap";
import { Col } from "react-bootstrap";
import { useState } from "react";
import { formatMoney } from "../../../constants/utils";

const Tds194RDeclarationReportModal = (props) => {
  const { open, onCancel, tds194RDeclarationReportData, rowData } = props;

  return (
    <CommonDialog
      onCancel={onCancel}
      open={open}
      secondaryButtonLabel={"Cancel"}
      title={"194R TDS Declaration Report"}
      maxWidth="lg"
    >
      <Grid container spacing={2}>
        <Col lg={12} xs={12}>
          <Card className="height-equal">
            <CardBody>
              <div className="user-status table-responsive text-center">
                <table className="table table-bordernone mb-20">
                  <thead>
                    <tr>
                      <th scope="col">Region</th>
                      <th scope="col">Total Customer</th>
                      <th scope="col">Agreed Count</th>
                      <th scope="col">Disagreed Count</th>
                      <th scope="col">Approval Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{rowData.regionName}</td>
                      <td>{rowData.totalCount}</td>
                      <td>{rowData.agreedCount}</td>
                      <td>{rowData.disagreedCount}</td>
                      <td>{rowData.agreedCount + rowData.disagreedCount}</td>
                    </tr>
                  </tbody>
                </table>
                <hr></hr>
                <table className="table table-bordernone mb-0 mt-10">
                  <thead>
                    <tr>
                      <th scope="col">SNO</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Customer Code</th>
                      <th scope="col">Status</th>
                      <th scope="col">Approval Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tds194RDeclarationReportData?.map((data, index) => (
                      <tr key={data.customerCode}>
                        <td>{index + 1}</td>

                        <td>{data.subParty}</td>
                        <td>{data.customerCode}</td>
                        <td
                          style={{
                            color:
                              data.status === "Agreed"
                                ? "green"
                                : data.status === "Disagreed"
                                ? "red"
                                : "",
                            fontWeight:
                              data.status === "Agreed" ||
                              data.status === "Disagreed"
                                ? "bold"
                                : "",
                          }}
                        >
                          {data.status}
                        </td>
                        <td>{data.agreedDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Grid>
    </CommonDialog>
  );
};
export default Tds194RDeclarationReportModal;
