import { CircularProgress } from "@mui/material";

const CommonLoader = (props) => {
  return (
    <div>
      {/* Overlay Background */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.3)", // Light color with transparency
          zIndex: 12,
          pointerEvents: "none", // Makes the background non-editable
        }}
      />
      {/* Loader Container */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: "50%",
          left: "50%",
          zIndex: 10,
          transform: "translate(-50%, -50%)",
          width: "20%",
        }}
      >
        <CircularProgress disableShrink />
        <span style={{ position: "absolute", top: "42px", left: "120px" }}>
          <b>Loading...</b>
        </span>
      </div>
    </div>
  );
};

export default CommonLoader;
