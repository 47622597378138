import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardBody, Container, Row } from "reactstrap";
import { Button } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { SimCardDownloadRounded } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonAccordion from "../../common/commonAccordion";
import CommonTable from "../../common/commonTable";
import {
  tds194RDeclarationReportTableData,
  tds194RDeclarationReportTableHeader,
} from "../../common/commonConstant";
import { useEffect } from "react";
import {
  exportToExcelBalanceConfirmationConsolidate,
  exportToExcelTds194RDeclarationConsolidate,
  getAllDistributorMasters,
  getTds194RDeclarationConsolidateReport,
  getTds194RDeclarationConsolidateReportByRegion,
} from "../../api/service";
import CommonLoader from "../../common/commonLoader";
import moment from "moment";
import Tds194RDeclarationReportModal from "./tds194RDeclarationReportModal";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const status = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Reject",
    label: "Reject",
  },
];

const Tds194RDeclarationReport = () => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [tds194RDeclarationReportData, setTds194RDeclarationReportData] =
    useState();
  const [rowData, setRowData] = useState({
    regionName: "",
    totalCount: "",
    totalClosingBalance: "",
    totalCustomerBalance: "",
    approvedCount: "",
    percentage: "",
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState([]);

  const filterInitialValues = {
    searchFinancialYear: "",
  };
  const [searchData, setSearchData] = useState(filterInitialValues);

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {
    setLoading(true);
    getTds194RDeclarationConsolidateReport(page, rowsPerPage).then((res) => {
      setLoading(false);
      setData(res.response.responseObject);
    });

    getAllDistributorMasters(page, rowsPerPage).then((res) => {
      setSearch(res.response.responseObject);
    });
  };
  const [open, setOpen] = useState(false);

  const onOpenModal = (row) => {
    if (row.regionMasterId || row.regionName === "All") {
      setLoading(true);

      getTds194RDeclarationConsolidateReportByRegion(
        row.regionMasterId ? row.regionMasterId : 0
      ).then((res) => {
        setLoading(false);
        setTds194RDeclarationReportData(res.response.responseObject);
      });
      setRowData(row);
      setOpen(true);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const exportData = () => {
    setLoading(true);
    exportToExcelTds194RDeclarationConsolidate().then(() => {
      setLoading(false);
    });
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb
        title="194R TDS Declaration Report"
        parent={
          <div className={classes.btnJust}>
            <Button
              color="primary"
              sx={{ marginLeft: 1 }}
              variant="contained"
              type="button"
              className={classes.btnClass}
              onClick={() => {
                exportData();
              }}
            >
              <SimCardDownloadRounded></SimCardDownloadRounded>
            </Button>
          </div>
        }
      />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  tableData={data}
                  tableHeaders={tds194RDeclarationReportTableHeader}
                  tableColumns={tds194RDeclarationReportTableData}
                  editId={"regionMasterId"}
                  editMode={true}
                  onOpenModal={onOpenModal}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                />
              </div>
            </CardBody>
          </Card>
        </Row>

        <Tds194RDeclarationReportModal
          open={open}
          onCancel={() => {
            setOpen(false);
            setTds194RDeclarationReportData([]);
          }}
          tds194RDeclarationReportData={tds194RDeclarationReportData}
          rowData={rowData}
        />
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default Tds194RDeclarationReport;
