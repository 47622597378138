import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import Routers from "./routes";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#292929",
    },
    secondary: {
      main: "#f91e26",
    },
  },
  "& label.Mui-focused": {
    color: "#292929!important",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#f91e26!important",
    },
  },
});

const Root = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <BrowserRouter basename={"/"}>
          <PerfectScrollbar>
            <Routers />
          </PerfectScrollbar>
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
