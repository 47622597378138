export const formatMoney = (value) => {
  return value
    ? new Intl.NumberFormat("en-IN", {
        style: "decimal",
        maximumFractionDigits: 2,
      }).format(value)
    : value;
};

export const getDivisionName = (divisionNo) => {
  let divisionName = "";
  if (divisionNo) {
    if (divisionNo === "10") {
      return "Dixcy"; // Core
    } else if (divisionNo === "20") {
      return "Maximus"; // Max
    } else if (divisionNo === "30") {
      return "Levis";
    } else if (divisionNo === "40") {
      return "Enamor";
    } else if (divisionNo === "50") {
      return "Thermal";
    }
  }
  return divisionName;
};
