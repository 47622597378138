import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, TextField } from "@mui/material";
import CommonDialog from "../../common/commonDialog";

const useStyles = makeStyles((theme) => ({
  checkLabelFont: {
    marginLeft: "12px!important",
    color: "#292929!important",
    fontSize: "1rem!important",
  },
  checkFont: {
    display: "flex",
    justifyContent: "start",
    marginLeft: "0px!important",
  },
}));

const UpdateRegionBmModal = (props) => {
  const classes = useStyles();

  const {
    open,
    onCancel,
    onUpdate,
    distributorData,
    setDistributorData,
    bmComboList,
    regionData,

  } = props;
  const onInputChange = (event) => {
    const { name, value } = event.target;
    distributorData[name] = value;
    setDistributorData({ ...distributorData });
  };
  return (
    <CommonDialog
      onCancel={onCancel}
      onUpdate={onUpdate}
      open={open}
      primaryButtonLabel={"Update"}
      secondaryButtonLabel={"Cancel"}
      title={" Distributor Master"}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Region"
            defaultValue=""
            size="small"
            name="regionMasterId"
            value={distributorData.regionMasterId}
            onChange={onInputChange}
          >
            {regionData.map((option) => (
              <MenuItem
                key={option.regionMasterId}
                value={option.regionMasterId}
              >
                {option.regionName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="BM"
            defaultValue=""
            size="small"
            name="bm"
            value={distributorData.bm}
            onChange={onInputChange}
          >
            {bmComboList.map((option) => (
              <MenuItem key={option.userId} value={option.userId}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={12} xs={12}>
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className={classes.checkLabelFont}
            >
              Status
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="status"
              value={distributorData.status}
              onChange={onInputChange}
            >
              <FormControlLabel
                value="Y"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    fontSize={15}
                    sx={{ color: "#292929" }}
                  >
                    ACTIVE
                  </Typography>
                }
                className={classes.checkFont}
              />
              <FormControlLabel
                value="N"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    fontSize={15}
                    sx={{ color: "#292929" }}
                  >
                    INACTIVE
                  </Typography>
                }
                className={classes.checkFont}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </CommonDialog>
  );
};
export default UpdateRegionBmModal;
