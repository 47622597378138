import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
 
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  muiShadow: {
    boxShadow: "none!important",
  },
  muiArrow: {
    minHeight: "0!important",
    marginTop: `${theme.spacing(0)} !important`,
  },
}));



const CommonAccordion = (props) => {
  const classes = useStyles();

  const {
    children,
    accordionTitle,
  } = props;

  return (
    <Accordion className={classes.muiShadow} defaultExpanded="true">
    <AccordionSummary
      className={classes.muiArrow}
      expandIcon={<ExpandMore />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography sx={{ marginLeft: "auto" }}>
        {accordionTitle}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
    {children}
    </AccordionDetails>
  </Accordion>
  );
};
export default CommonAccordion;
