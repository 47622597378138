import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Grid, TextField } from "@mui/material";
import CommonDialog from "../../common/commonDialog";
import { Card, CardBody } from "reactstrap";
import { Col } from "react-bootstrap";
import { useState } from "react";


const status = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Reject",
    label: "Reject",
  },
];

const CreditExtensionModal = (props) => {
  const {
    open,
    onCancel,
    onSave,
    creditExtensionData,
    setCreditExtensionData,
  } = props;

  const [checked, setChecked] = useState(creditExtensionData.sendNotify === "");

  const onInputChange = (event) => {
    setChecked(event.target.checked);
    const { name, value } = event.target;
    creditExtensionData[name] = value;
    setCreditExtensionData({ ...creditExtensionData });
  };

  const onApprovalStatusChange = (e, index, data) => {
    creditExtensionData.creditExtensionDtlList[index].approvalStatus = e.target
      .checked
      ? "Y"
      : "N";

    let totalApprovedAmt = creditExtensionData.totalApprovedExtensionAmount;
    if (e.target.checked) {
      totalApprovedAmt += data.balanceAmount;
    } else {
      totalApprovedAmt -= data.balanceAmount;
    }
    creditExtensionData["totalApprovedExtensionAmount"] = totalApprovedAmt;
    setCreditExtensionData({ ...creditExtensionData });
  };

  return (
    <CommonDialog
      onCancel={onCancel}
      onSave={onSave}
      open={open}
      primaryButtonLabel={"Save"}
      secondaryButtonLabel={"Cancel"}
      title={"Credit Extension"}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Customer Code"
            name="customerCode"
            type="text"
            size="small"
            value={creditExtensionData.customerCode}
            onChange={onInputChange}
          />
        </Grid>

        {/* <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Invoice No"
            name="invoiceNumber"
            type="text"
            size="small"
            value={creditExtensionData.invoiceNumber}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Invoice Date"
            name="documentDate"
            type="text"
            size="small"
            value={creditExtensionData.documentDate}
            onChange={onInputChange}
          />
        </Grid>
 */}
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Distributor Name"
            name="distributorId"
            type="text"
            size="small"
            value={creditExtensionData.distributorName}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Credit Extension Days"
            name="cdextensionDays"
            type="text"
            size="small"
            value={creditExtensionData.cdextensionDays}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Approved Days"
            name="approvedDays"
            type="text"
            size="small"
            value={creditExtensionData.approvedDays}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="CD Requested Amount"
            name="totalExtensionAmount"
            type="text"
            size="small"
            value={creditExtensionData.totalExtensionAmount?.toFixed(0)}
            onChange={onInputChange}
            disabled
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="CD Approved Amount"
            name="totalApprovedExtensionAmount"
            type="text"
            size="small"
            value={creditExtensionData.totalApprovedExtensionAmount?.toFixed(0)}
            onChange={onInputChange}
            disabled
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Reason"
            name="reason"
            type="text"
            size="small"
            value={creditExtensionData.reason}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Other Reason"
            name="otherReason"
            type="text"
            size="small"
            value={creditExtensionData.otherReason}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Additional Remarks"
            name="remarks"
            type="text"
            size="small"
            value={creditExtensionData.remarks}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            select
            id="outlined-search"
            label="Approval Status"
            name="approvalStatus"
            type="text"
            size="small"
            value={creditExtensionData.approvalStatus}
            onChange={onInputChange}
          >
            {status.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Approval Remarks"
            name="approvalRemarks"
            type="text"
            size="small"
            value={creditExtensionData.approvalRemarks}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={onInputChange}
                name="sendNotify"
                value={checked ? "N" : "Y"}
              />
            }
            label="Send Notification"
            labelPlacement="start"
          />
        </Grid>

        <Col lg={12} xs={12}>
          <Card className="height-equal">
            <CardBody>
              <div className="user-status table-responsive text-center">
                <table className="table table-bordernone mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Invoice No</th>
                      <th scope="col">Invoice Date</th>
                      <th scope="col">Due Date</th>
                      <th scope="col">Invoice Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creditExtensionData?.creditExtensionDtlList?.map(
                      (data, index) => (
                        <tr key={data.creditExtensionDtlId}>
                          <td>{data.invoiceNumber}</td>
                          <td>{data.documentDate}</td>
                          <td>{data.cddueDate}</td>
                          <td>{data.balanceAmount.toFixed(0)}</td>
                          <td>
                            <div>
                              <span>
                                <input
                                  type="checkbox"
                                  defaultChecked={data.approvalStatus === "Y"}
                                  onChange={(e) =>
                                    onApprovalStatusChange(e, index, data)
                                  }
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Grid>
    </CommonDialog>
  );
};
export default CreditExtensionModal;
