import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { getAllOpenCreditHistory } from "../../api/service";
import CommonLoader from "../../common/commonLoader";
import Breadcrumb from "../../common/breadcrumb";
import {
  CardOpenCreditValue,
  openCreditTableData1,
  openCreditTableHeader1,
} from "../../common/commonCardConstant";
import CommonCardDesign from "../../common/commonCardDesign";
import { useCustomerCode } from "../../context/CustomerCodeProvider";

const useStyles = makeStyles((theme) => ({
  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },
}));

const OpenCreditHistory = () => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedCustomerCode, selectedCustomerName } = useCustomerCode();

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = () => {
    setLoading(true);
    getAllOpenCreditHistory(selectedCustomerCode).then((res) => {
      setLoading(false);
      let listData = res.response.responseObject;
      setData(listData);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <Fragment>
      {loading ? <CommonLoader /> : null}
      <Breadcrumb title="Open Credit History" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <CardBody className={classes.cardbody}>
            <CommonCardDesign
              tableData={data}
              tableHeaders={openCreditTableHeader1}
              tableColumns={openCreditTableData1}
              tableModal={CardOpenCreditValue}
              rowsPerPageOptions={[25, 50, 100]}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ float: "right" }}
              displayPagination={false}
              showPdf={true}
              from={"Open Credit History"}
              maxHeight={600}
            />
          </CardBody>
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default OpenCreditHistory;
