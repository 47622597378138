import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import MenuItem from "@mui/material/MenuItem";
import { Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import { Button } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { PersonSearch, Add, Refresh } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import DistributorMappingModal from "./distributorMappingModal";
import CommonAccordion from "../../common/commonAccordion";
import CommonTable from "../../common/commonTable";
import {
  distributorMappingTableData,
  distributorMappingTableHeader,
} from "../../common/commonConstant";
import { useEffect } from "react";
import {
  getAllDistributorMastersMapping,
  getAllDivisionMasters,
  getAllRegionMasters,
  getAllStateMasters,
  getGroupUserForCombo,
  saveOrUpdateDistributorMasterDtl,
  searchDistributorMasterMapping,
} from "../../api/service";

const useStyles = makeStyles((theme) => ({
  btnClass: {
    padding: "0.5rem 1.5rem!important",
    fontWeight: 600,
  },

  btnJust: {
    display: "flex!important",
    justifyContent: "end!important",
  },

  cardbody: {
    padding: `${theme.spacing(1.8, 1.3)} !important`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)} !important`,
      margin: `${theme.spacing(0, 1)} !important`,
    },
  },

  muiGrid: {
    marginTop: "-2vh",
    [theme.breakpoints.down("sm")]: {
      lineHeight: 5,
    },
  },
}));

const status = [
  {
    value: "Y",
    label: "Active",
  },
  {
    value: "N",
    label: "In-Active",
  },
];

const DistributorMapping = () => {
  const classes = useStyles();
  const [totalRecord, setTotalRecord] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const initialValues = {
    distributorMasterId: null,
    distributorMasterDtlId: null,
    divisionMasterId: null,
    mainAgent: "",
    subAgent: "",

    sapPartyCode: "",

    bm: null,
    bsm: null,
    asm: null,
    coOrdinatorOne: null,
    coOrdinatorTwo: null,

    bmName: "",
    bmEmail: "",
    coOrdinatorOneName: "",
    coOrdinatorOneEmail: "",
    bceName: "",
    bceEmail: "",
    bceMobileNo: "",
    bcmName: "",
    bcmEmail: "",
    bcmMobileNo: "",
    coOrdinatorTwoName: "",
    coOrdinatorTwoEmail: "",
    paymentTerms1Days: 30,
    paymentTerms1Percent: "",
    paymentTerms2Days: 45,
    paymentTerms2Percent: "",
    paymentTerms3Days: 60,
    paymentTerms3Percent: "",
    paymentTerms4Days: 75,
    paymentTerms4Percent: "",
    paymentTerms: "",
  };

  const [data, setData] = useState([]);
  const [distributorData, setDistributorData] = useState(initialValues);
  const [bmComboList, setBmComboList] = useState([]);
  const [bsmComboList, setBsmComboList] = useState([]);
  const [asmComboList, setAsmComboList] = useState([]);
  const [coOrdinatorComboList, setCoOrdinatorComboList] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [stateData, setStateData] = useState([]);

  const filterInitialValues = {
    searchSubParty: "",
    searchCustomerCode: "",
    searchStatus: "",
  };
  const [searchData, setSearchData] = useState(filterInitialValues);

  const onFilterInputChange = (event) => {
    const { name, value } = event.target;
    searchData[name] = value;
    setSearchData({ ...searchData });
  };

  useEffect(() => {
    loadListData();
    loadGroupList();
  }, []);

  const loadListData = () => {
    getAllDistributorMastersMapping(page, rowsPerPage).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
    getAllDivisionMasters(page, rowsPerPage).then((res) => {
      setDivisionData(res.response.responseObject);
    });

    getAllRegionMasters(page, rowsPerPage).then((res) => {
      setRegionData(res.response.responseObject);
    });

    getAllStateMasters(page, rowsPerPage).then((res) => {
      setStateData(res.response.responseObject);
    });
  };

  const loadGroupList = () => {
    getGroupUserForCombo(page, 100).then((res) => {
      setBmComboList(res.response.responseMap.bmList);
      setBsmComboList(res.response.responseMap.bsmList);
      setAsmComboList(res.response.responseMap.asmList);
      setCoOrdinatorComboList(res.response.responseMap.coOrdinatorList);
    });
  };
  const [open, setOpen] = useState(false);
  const [openUpdateMod, setOpenUpdateMod] = useState(false);

  const onOpenModal = (data = initialValues) => {
    setDistributorData(data);
    setOpen(true);
  };

  const onOpenUpdateModal = () => {
    setOpenUpdateMod(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllDistributorMastersMapping(newPage, rowsPerPage).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getAllDistributorMastersMapping(0, event.target.value).then((res) => {
      setData(res.response.responseObject);
      setTotalRecord(res.response.responseMap.totalCount);
    });
  };

  const onHandleSave = () => {
    saveOrUpdateDistributorMasterDtl(distributorData).then((res) => {
      if (distributorData.distributorMappingId) {
        toast.success("Successfully Updated !", { autoClose: 1000 });
      } else {
        toast.success("Successfully Created !", { autoClose: 1000 });
      }
      setOpen(false);
      setDistributorData(initialValues);
      loadListData();
    });
  };

  const filterData = () => {
    searchDistributorMasterMapping(searchData, page, rowsPerPage).then(
      (res) => {
        setData(res.responseMap.data);
      }
    );
  };

  const onHandleUpdate = () => {};

  return (
    <Fragment>
      <Breadcrumb
        title=" Distributor Mapping"
        parent={
          <div className={classes.btnJust}>
            <Button
              color={"secondary"}
              variant="contained"
              type="button"
              className={`${classes.btnClass}`}
              startIcon={<Add></Add>}
              onClick={() => onOpenModal()}
            >
              Add Distributor Mapping
            </Button>
          </div>
        }
      />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <CommonAccordion accordionTitle={"Advanced Search"}>
            <Grid container columnSpacing={2} className={classes.muiGrid}>
              {/* <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  label="Sub Party"
                  size="small"
                  name="searchSubParty"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="fullWidth"
                  value={searchData.searchSubParty}
                  onChange={onFilterInputChange}
                />
              </Grid> */}
              <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  label="SAP Party Code"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="fullWidth"
                  name="searchCustomerCode"
                  value={searchData.searchCustomerCode}
                  onChange={onFilterInputChange}
                />
              </Grid>
              {/* <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Status"
                  size="small"
                  value={searchData.searchStatus}
                  onChange={onFilterInputChange}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}

              <Grid
                item
                sm={3}
                xs={12}
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => filterData()}
                >
                  <PersonSearch></PersonSearch>
                </Button>
                <Button
                  color="primary"
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  type="button"
                  className={classes.btnClass}
                  onClick={() => {
                    setSearchData(filterInitialValues);
                    loadListData();
                  }}
                >
                  <Refresh></Refresh>
                </Button>
              </Grid>
            </Grid>
          </CommonAccordion>
          <Card>
            <CardBody className={classes.cardbody}>
              <div>
                <CommonTable
                  tableData={data}
                  tableHeaders={distributorMappingTableHeader}
                  tableColumns={distributorMappingTableData}
                  editId={" distributorMappingId"}
                  editMode={true}
                  onOpenModal={onOpenModal}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={totalRecord}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ float: "right" }}
                />
              </div>
            </CardBody>
          </Card>
        </Row>

        <DistributorMappingModal
          open={open}
          onCancel={() => {
            setOpen(false);
            setDistributorData(initialValues);
          }}
          onSave={onHandleSave}
          distributorData={distributorData}
          setDistributorData={setDistributorData}
          bsmComboList={bsmComboList}
          bmComboList={bmComboList}
          asmComboList={asmComboList}
          coOrdinatorComboList={coOrdinatorComboList}
          divisionData={divisionData}
          regionData={regionData}
          stateData={stateData}
          data={data}
        />
      </Container>
      {/* <!-- Container-fluid Ends--> */}
      <ToastContainer autoClose={1000} />
    </Fragment>
  );
};

export default DistributorMapping;
