import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextEditor = (props) => {
  const [editorHtml, setEditorHtml] = React.useState("");

  React.useEffect(() => {
    setEditorHtml(props.htmlCode);
  }, [props.htmlCode]);

  const quillModules = {
    toolbar: [
      [{ header: "1" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const quillFormats = [
    "header",
    "font",
    "size",
    "font-size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const handleChange = (html) => {
    setEditorHtml(html);
    if (props.setHtmlCode !== undefined && props.setHtmlCode !== null) {
      props.setHtmlCode(html);
    }
  };

  return (
    //  <div style={{'min-height':'500px'}}>
    <div style={{ "min-height": "300px" }}>
      <ReactQuill
        theme={"snow"}
        onChange={handleChange}
        value={editorHtml || ""}
        modules={quillModules}
        formats={quillFormats}
        placeholder={""}
      />
    </div>
  );
};

export default RichTextEditor;
