import React, { createContext, useState, useContext } from "react";

const CustomerCodeContext = createContext();

export const CustomerCodeProvider = ({ children }) => {
  const [selectedCustomerCode, setSelectedCustomerCode] = useState("");
  const [selectedCustomerName, setSelectedCustomerName] = useState("");

  return (
    <CustomerCodeContext.Provider
      value={{
        selectedCustomerCode,
        setSelectedCustomerCode,
        selectedCustomerName,
        setSelectedCustomerName,
      }}
    >
      {children}
    </CustomerCodeContext.Provider>
  );
};

export const useCustomerCode = () => useContext(CustomerCodeContext);
