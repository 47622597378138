import React, { Fragment } from "react";
import LoginTabset from "./loginTabset";
import { ArrowLeft } from "react-feather";
import Slider from "react-slick";
import logo from "../../assets/images/logo.png";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";
import { Card, CardBody, Col, Container, Media, Row } from "reactstrap";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme ) => ({
	Boxh3:{
		marginTop: '-16vh',
		[theme.breakpoints.down('sm')]: {
			marginTop: '2vh',
			  },
			  [theme.breakpoints.between('lg', 'xl')]: {
				marginTop: '-16vh',
				  },
				  [theme.breakpoints.between('sm', 'lg')]: {
					marginTop: '-16vh',
					  },
	},
	
	typo:{
		top: theme.spacing(3.75),
		position: 'relative',
	},
	image:{
		position:'absolute',
		opacity:0.1,
		[theme.breakpoints.down('sm')]: {
			width: '80%',
			  },
	},

	cardBody: {
		padding: theme.spacing(3.75),
		margin: `${theme.spacing(0, 15)} !important`,
		

		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0),
			backgroundColor: 'transparent',
			margin: `${theme.spacing(0, 6.25)} !important`
			  },
		
	},

	trans:{
		color: 'white',
		fontSize:'26px !important',
		marginBottom:'4vh',
		textTransform: 'capitalize',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '1vh',
			fontSize:'18px !important',
			  },
			  [theme.breakpoints.between('lg', 'xl')]: {
				marginBottom: '4vh',
				  },
				  [theme.breakpoints.between('sm', 'lg')]: {
					marginBottom: '4vh',
					  },
	},
	
	
	}))


const Login = () => {
	
const classes = useStyles()

	return (
		<Fragment>
			<div className="page-wrapper">
				<div className="authentication-box">
					<Container>
						<Row>
							<Col className="col-md-12 col-xs-12 col-lg-6 col-sm-12 p-0 card-left">
								<Card className="bg-primary" >
									
										<Media alt="" src={logo} className={classes.image} />
									
									 {/* <Slider className="single-item" {...settings}>  */}

										<div>

											<div className={classes.Boxh3}>
											<Box component="h2" className={classes.trans}>Modenik Transparency App</Box>

												<Box component="h3">Welcome</Box>
												<Box component="h3">Evolving Everyday For An Elevated Lifestyle</Box>
												</div>
												<Box component="div" className={classes.typo}>
												<Typography paragraph>
													We're the sum total of diverse,
												</Typography>
												<Typography paragraph>
												
													vivid minds and buoyant human souls. We are a
												</Typography>
												<Typography paragraph>
													four-decade old legacy knitted in a new-age narrative.
												</Typography>
												</Box>
											
										</div>
										{/* <div>
											<div>
												<h3>Welcome to Modenik</h3>
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy.
												</p>
											</div>
										</div>
										<div>
											<div>
												<h3>Welcome to Modenik</h3>
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy.
												</p>
											</div>
										</div> */}
									 {/* </Slider>  */}
								</Card>
							</Col>
							<Col className="col-md-12 col-xs-12 col-lg-6 col-sm-12  p-0 card-right">
								<Card className="tab2-card">
									<Box className={classes.cardBody}>
										<LoginTabset />
									</Box>
								</Card>
							</Col>
						</Row>
						{/* <a
							href="https://Modenik-react.vercel.app/"
							target="_blank"
							rel="noreferrer"
							className="btn btn-primary back-btn"
						>
							<ArrowLeft />
							back
						</a> */}
					</Container>
				</div>
			</div>
		</Fragment>
	);
};

export default Login;
