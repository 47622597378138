import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, TextField } from "@mui/material";
import CommonDialog from "../../common/commonDialog";
import { useEffect, useState } from "react";
import { getUserListByGroup } from "../../api/service";
import CommonLoader from "../../common/commonLoader";

const useStyles = makeStyles((theme) => ({
  checkLabelFont: {
    marginLeft: "12px!important",
    color: "#292929!important",
    fontSize: "1rem!important",
  },
  checkFont: {
    display: "flex",
    justifyContent: "start",
    marginLeft: "0px!important",
  },
}));

const UserModal = (props) => {
  const classes = useStyles();
  const [reportingToArray, setReportingToArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { open, onCancel, onSave, userData, setUserData, groupData } = props;
  const isAdminUser =
  JSON.parse(sessionStorage.getItem("userData")).groupId === 1;
  useEffect(() => {
    if (userData.userId !== null && userData.reportingGroupId != null) {
      getReportingToListData(userData.reportingGroupId);
    }
  }, [userData.userId, userData.reportingGroupId]);

  const getReportingToListData = (groupId) => {
    setIsLoading(true);
    getUserListByGroup(groupId).then((res) => {
      setReportingToArray(res.response.responseObject);
      setIsLoading(false);
    });
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "reportingGroupId") {
      getReportingToListData(value);
    }
    userData[name] = value;
    setUserData({ ...userData });
  };

  return (
    <CommonDialog
      onCancel={onCancel}
      onSave={onSave}
      open={open}
      primaryButtonLabel={"Save"}
      secondaryButtonLabel={"Cancel"}
      title={"User"}
    >
      {isLoading ? <CommonLoader /> : null}

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Name"
            name="name"
            type="text"
            size="small"
            value={userData.name}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="User Name"
            name="username"
            size="small"
            value={userData.username}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Email Id"
            type="mail"
            name="emailId"
            size="small"
            value={userData.emailId}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Mobile No"
            type="number"
            size="small"
            name="mobileNo"
            value={userData.mobileNo}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Password"
            type={isAdminUser && userData.groupId !== 1 ?"text":"password"}
            size="small"
            name="password"
            value={userData.password}
            onChange={onInputChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Confirm Password"
            type={isAdminUser && userData.groupId !== 1?"text":"password"}
            size="small"
            name="confirmPassword"
            value={userData.confirmPassword}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Employee Id"
            size="small"
            name="employeeId"
            value={userData.employeeId}
            onChange={onInputChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-search"
            label="Designation"
            size="small"
            name="designation"
            value={userData.designation}
            onChange={onInputChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Group"
            size="small"
            name="groupId"
            value={userData.groupId}
            onChange={onInputChange}
          >
            {groupData.map((option) => (
              <MenuItem key={option.groupId} value={option.groupId}>
                {option.groupName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Reporting Group"
            size="small"
            name="reportingGroupId"
            value={userData.reportingGroupId}
            onChange={onInputChange}
          >
            {groupData.map((option) => (
              <MenuItem key={option.groupId} value={option.groupId}>
                {option.groupName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            id="outlined-select-currency"
            select
            label="Reporting To"
            size="small"
            name="reportingTo"
            value={userData.reportingTo}
            onChange={onInputChange}
          >
            {reportingToArray.map((option) => (
              <MenuItem key={option.userId} value={option.userId}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={12} xs={12}>
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className={classes.checkLabelFont}
            >
              Status
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="status"
              value={userData.status}
              onChange={onInputChange}
            >
              <FormControlLabel
                value="Y"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    fontSize={15}
                    sx={{ color: "#292929" }}
                  >
                    ACTIVE
                  </Typography>
                }
                className={classes.checkFont}
              />
              <FormControlLabel
                value="N"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#f91e26",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 23,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    fontSize={15}
                    sx={{ color: "#292929" }}
                  >
                    INACTIVE
                  </Typography>
                }
                className={classes.checkFont}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </CommonDialog>
  );
};
export default UserModal;
